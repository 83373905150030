import React, {Component} from 'react';
import {Icon, IconButton, TextField} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";

class HAutocompleteAsync extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            items: [],
            minSearchLength: 1,
            isFocused: false,
            isLoaded: false
        };
    }

    async componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            minSearchLength: nextProps.minSearchLength ? nextProps.minSearchLength : 1,
        })
    }

    handleSearchTextChange(event){
        let searchText = event.target.value;
        this.setState({
            searchText:searchText,
        },()=>{
            if (this.state.searchText.length >= this.props.minSearchLength) {
                this.performSearch()
            }
        });
    }

    async performSearch(){
        try {
            this.setState({isLoaded: true});
            let items = await this.props.getItems(this.state.searchText);

            this.setState({
                items,
                isLoaded: false
            });
        } catch(error) {
            console.warn(error)
        }
    }

    handleOnFocus() {
        this.setState({isFocused: true})
    }

    clearSearch(){
        this.setState({searchText:"", isFocused: false});
    }

    getStartAdornment() {
        return(
            !this.state.isFocused&& <Icon style={{fontSize:20}}>search</Icon>
        )
    }

    getEndAdornment(){
        if(this.props.showClearButton && this.state.isFocused){
            return(
                <IconButton style={{height:32, width:32, marginRight:-10}}
                            onMouseDown={()=>{this.clearSearch()}}>
                    <Icon style={{marginTop:-6, fontSize:20}}>clear</Icon>
                </IconButton>
            )
        }
        else{
            return null
        }
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <TextField
                    placeholder={this.state.isFocused ? this.props.placeholder : `  ${this.props.placeholder}`}
                    style={this.props.style}
                    disabled={this.props.disabled}
                    InputProps={{
                        style: {height:38, color:'#222222'},
                        startAdornment: this.getStartAdornment(),
                        endAdornment: this.getEndAdornment(),
                    }}
                    value={this.state.searchText}
                    onChange={(event)=>{this.handleSearchTextChange(event)}}
                    onFocus={() => this.handleOnFocus()}
                    onBlur={()=>this.clearSearch()}
                    margin="none"
                    variant={this.props.variant}
                />
                {this.state.searchText && this.state.searchText.length > this.state.minSearchLength &&
                <div style={{width:"100vw", height:"100vh", position:"fixed", left:0, top:0, zIndex:15, backgroundColor:'rgba(0,0,0,0.2)'}}/>

                }

                {this.state.searchText && this.state.searchText.length > this.state.minSearchLength &&
                <div style={{
                    width: this.props.width, backgroundColor: "#FFFFFF", maxHeight: this.props.maxHeight, overflowY: 'auto', borderRadius: 5, position: 'absolute', top: 60,
                    left: this.props.align === 'left' ? 0 : null, right: this.props.align === 'right' ? 0 : null, zIndex:40,
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                }}>
                    <div style={{width:"100%", position:"relative"}}>
                        {this.state.isLoaded && <LinearProgress color="primary" style={{position:"absolute", width:"100%", top:0, left:0}}/>}
                        {this.state.items.length > 0
                            ? (
                                this.state.items.map((item, index) => {
                                    return (
                                        <div style={{width: "100%"}} key={"item" + index} onMouseDown={() => {
                                            this.props.onHandleClick(item)
                                        }}>
                                            {this.props.renderItem(item)}
                                        </div>
                                    )
                                })
                            )
                            : <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', height: 60, width: '100%', textAlign: 'left'}}>
                                <Icon style={{color: '#797979', fontSize: 20}}>error</Icon><p style={{color: '#797979', fontSize: 14, paddingLeft: 7}}>Aucun résultat n'a été trouvé</p>
                            </div>
                        }
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default HAutocompleteAsync;

import {User} from './User'
import {EventEmitter} from "fbemitter";


export default class CredentialsStorage {

    static storeProxyCompany(value: boolean) {
        return new Promise((resolve, reject) => {
            localStorage.setItem("@cred:proxyCompany", value);
            resolve()
        })
    }

    static storeCredentials(username, password) {
        return new Promise((resolve) => {
            localStorage.setItem("@cred:username", username);
            localStorage.setItem("@cred:password", password);
            resolve()
        })
    }

    static storeJWT (jwt) {
        return new Promise((resolve) => {
            localStorage.setItem("@jwt", jwt);
            resolve()
        })
    }

    static getJWT () {
        return new Promise((resolve) => {
            resolve( JSON.parse(localStorage.getItem("@jwt")));
        })
    }

    static storeUser (user) {
        return new Promise((resolve) => {
            localStorage.setItem("@user", user);
            resolve()
        })
    }

    static getProxyCompany() {
        return new Promise((resolve, reject) => {
            resolve( JSON.parse(localStorage.getItem("@cred:proxyCompany")));
        })
    }

    static getCredentials() : Promise<User> {
        return new Promise((resolve, reject) => {
            resolve([localStorage.getItem("@cred:username"),localStorage.getItem("@cred:password")]);
        })
    }

    static removeAll(){
        return new Promise((resolve) => {
            localStorage.removeItem("@cred:proxyCompany");
            localStorage.removeItem("@cred:username");
            localStorage.removeItem("@user");
            localStorage.removeItem("@cred:password");
            localStorage.removeItem("@jwt");
            resolve()
        })
    }

    static removeJWT(){
        return new Promise((resolve) => {
            localStorage.removeItem("@jwt");
            resolve()
        })
    }

    static removeUser(){
        return new Promise((resolve) => {
            localStorage.removeItem("@user");
            resolve()
        })
    }


    static getUser() : Promise<User> {
        return new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem("@user")));
        })
    }

    static mount() {
        return new Promise((resolve, reject) => {
            CredentialsStorage.getUser().then((u) => {
                global.user = u;
                if(u !== null) {
                    global.jwt = u.jwt;
                } else {
                    global.jwt = "";
                }
                console.log(global.user, 'user')
                console.log(global.jwt, 'jwt')
                console.log(global.proxyCompany, 'proxyCompany')
                console.log(global.credentials, 'credentials')
                return CredentialsStorage.getProxyCompany();
            }).then((p) => {
                global.proxyCompany = p;
                return CredentialsStorage.getCredentials();
            }).then((c) => {
                global.credentials = c;
                resolve()
            })
        })
    }

    // static async mount() {
    //      const user = await CredentialsStorage.getUser();
    //             global.user = user;
    //             if(user !== null) {
    //                 global.jwt = user.jwt;
    //             } else {
    //                 global.jwt = "";
    //             }
    //
    //      const proxyCompany = await CredentialsStorage.getProxyCompany()
    //     if (proxyCompany) {
    //         global.proxyCompany = proxyCompany
    //     } else {
    //         global.proxyCompany = null
    //     }
    //      const credentials = await CredentialsStorage.getCredentials();
    //     if (credentials[0] !== null && credentials[1] !== null) {
    //         global.credentials = credentials
    //     } else {
    //         global.credentials = null
    //     }
    //     console.log(global.user, 'user')
    //     console.log(global.jwt, 'jwt')
    //     console.log(global.proxyCompany, 'proxyCompany')
    //     console.log(global.credentials, 'credentials')
    // }

    // static getProxyCompany() {
    //     return new Promise((resolve, reject) => {
    //         resolve(JSON.parse(localStorage.getItem("@cred:proxy_company")))
    //     })
    // }
    //
    // static storeLockDrawer(value: boolean) {
    //     return new Promise((resolve, reject) => {
    //         // console.log("Storing lock drawer at value : ", value);
    //         localStorage.setItem("@pref:lock_drawer", value);
    //         resolve()
    //     })
    // }
    //
    // static getLockDrawer() {
    //     return new Promise((resolve, reject) => {
    //         // console.log("Storing lock drawer at value : ", !!localStorage.getItem("@pref:lock_drawer"));
    //         resolve(!!localStorage.getItem("@pref:lock_drawer"))
    //     })
    // }
    //
    //
    // static removeProxyCompany(){
    //     return new Promise((resolve) => {
    //         localStorage.removeItem("@cred:proxy_company");
    //         resolve()
    //     })
    // }
    //
    // static storeUser(item) {
    //     return new Promise((resolve) => {
    //         global.user = item;
    //         global.jwt = item.jwt;
    //         localStorage.setItem("@cred:user", JSON.stringify(item));
    //         resolve();
    //     })
    // }
    //
    // static removeUser(){
    //     return new Promise((resolve) => {
    //         localStorage.removeItem("@cred:user");
    //         localStorage.removeItem("@cred:username");
    //         localStorage.removeItem("@cred:password");
    //         resolve()
    //     })
    // }
    //

    //
    // static getJWT() {
    //     return new Promise((resolve, reject) => {
    //         CredentialsStorage.getUser()
    //             .then((value) => {
    //                 if(value === null) resolve("");
    //                 resolve(value.jwt)
    //             })
    //     })
    // }
    //

    //
    // static getCredentials() : Promise<User> {
    //     return new Promise((resolve, reject) => {
    //         resolve([localStorage.getItem("@cred:username"),localStorage.getItem("@cred:password")]);
    //     })
    // }
    //

    //
    //
    static mountInstant() {
        global.emitter = new EventEmitter();
        global.language = 'fr';
    }

}

import type {LatLngLiteral} from '../interfaces/LatLngLitteral'
import {Geodesic} from "../others/Geodesic";
export class ConvertiblePosition implements LatLngLiteral{
    lat:number;
    lng:number;
    lon:number;
    latitude:number;
    longitude:number;

    constructor(lat:number, lng:number) {
      this.lat = lat;
      this.lng = lng;
      this.lon = lng;
      this.latitude = lat;
      this.longitude = lng;
    }

    isEqual(pos:ConvertiblePosition) {
        return this.lat === pos.lat && this.lng === pos.lng;
    }

    toLatLngLiteral() : LatLngLiteral {
      return {'lat': this.lat, 'lng': this.lng}
    }

    toGoogleLatLngLiteral() : any {
        return {'latitude': this.lat, 'longitude': this.lng}
    }

    static map(raw) {
        return new ConvertiblePosition(raw.lat, raw.lng);
    }

    static mapArray(raw) : Array<ConvertiblePosition> {
        let result:Array<ConvertiblePosition> = [];
        for(let item of raw) {
            result.push(ConvertiblePosition.map(item));
        }
        return result;
    }


  distanceTo(position:ConvertiblePosition) {
    return Geodesic.computeDistanceBetween(this, position);
  }
}

export class Texts {

    static getText(key) {
        let obj = Texts.loadTexts()[key], text, language = global.language ? global.language : 'fr';
        if (obj) {
            text = obj[language]
        }
        return text ? text : '-----'
    }

    static loadTexts() {
        return {
            classic_variant_type: {
                fr: "Trajets Classiques",
                en: "Regular Rides",
            },
            detour_variant_type: {
                fr: "Déviations",
                en: "Detour Rides",
            },
            hlp_variant_type: {
                fr: "Haut-le-pied",
                en: "Dead Mileages Rides",
            },
            turnaround_variant_type: {
                fr: "Retournements",
                en: "Turnaround Rides",
            },
            misc_variant_type: {
                fr: "Trajets Divers",
                en: "Misc Rides",
            },
            singular_classic_variant_type: {
                fr: "Trajet Classique",
                en: "Regular Ride",
            },
            singular_detour_variant_type: {
                fr: "Déviation",
                en: "Detour",
            },
            singular_hlp_variant_type: {
                fr: "Haut-le-pied",
                en: "Dead Mileages Ride",
            },
            singular_turnaround_variant_type: {
                fr: "Retournement",
                en: "Turnaround",
            },
            singular_misc_variant_type: {
                fr: "Trajet Divers",
                en: "Misc Ride",
            },
            loading_line_detail_text:{
                fr: "Chargement du détail de la ligne ...",
                en: "Loading line detail ...",
            },
            active_variant_tab_label: {
                fr: "TRAJETS ACTIFS",
                en: "ACTIVE RIDES",
            },
            archived_variant_tab_label: {
                fr: "TRAJETS ARCHIVÉS",
                en: "archived rides",
            },
            variant_creation_button_text: {
                fr: "AJOUTER",
                en: "ADD",
            },
            variant_creation_button_tooltip_text: {
                fr: "Ajouter un nouveau trajet",
                en: "Add a new ride",
            },
            variant_archive_button_tooltip_text:{
                fr: "Archiver le trajet",
                en: "Archive the ride",
            },
            variant_unarchive_button_tooltip_text:{
                fr: "Désarchiver le trajet",
                en: "Unarchive the ride",
            },
            variant_duplicate_button_tooltip_text:{
                fr: "Dupliquer le trajet",
                en: "Duplicate the ride",
            },
            stops_metadata_tooltip_text: {
                fr: "Arrêts",
                en: "Stops",
            },
            directions_metadata_tooltip_text: {
                fr: "Indications de guidage",
                en: "Driving directions",
            },
            danger_zones_metadata_tooltip_text: {
                fr: "Zones de danger",
                en: "Danger zones",
            },
            tunnel_zones_tooltip_text: {
                fr: "Zones tunnel",
                en: "Tunnel zones",
            },
            re_routing_metadata_tooltip_text: {
                fr: "Itinéraires de re-guidage",
                en: "Re-routing paths",
            },
            metadata_loading_text: {
                fr: "Chargement...",
                en: "Loading...",
            },
            empty_variant_type_text: {
                fr: "Aucun trajet de ce type n'est disponible pour cette ligne",
                en: "No ride of is type is available for this line",
            },
            empty_archived_variant_type_text: {
                fr: "Aucun trajet de ce type n'est disponible pour cette ligne",
                en: "No ride of is type is available for this line",
            },

            // DELETE VARIANT DIALOG

            delete_variant_modal_title:{
                fr: "Supprimer le trajet",
                en: "Delete ride :",
            },
            variant_deletion_text:{
                fr: "Suppression du trajet en cours ...",
                en: "Ride deletion in progress ...",
            },
            variant_deletion_warning_text:{
                fr: "En continuant, vous allez supprimer le trajet: ",
                en: "By proceeding, you will delete the ride: ",
            },
            variant_deletion_warning_text_separator:{
                fr: " vers ",
                en: " to ",
            },
            variant_deletion_warning_bold_text:{
                fr: "Attention, cette action est définitive let entrainera la suppression de tous les arrêts, indications de guidage, haut-le-pied et zones de danger pour tous les tracés de ce trajet.",
                en: "Be careful, this action is final and will cause the deletion of all stops, directions, dead mileages tracks, danger and tunnel zones for this ride",
            },
            delete_variant_modal_cancel_button_text:{
                fr: "Annuler",
                en: "Cancel",
            },
            delete_variant_file_modal_validate_button_text:{
                fr: "Supprimer",
                en: "Delete",
            },
            delete_variant_modal_success_snackbar_title:{
                fr: "Confirmation",
                en: "Confirmation",
            },
            delete_variant_modal_success_snackbar_text:{
                fr: "Le trajet a été supprimé avec succès",
                en: "The ride was successfully deleted",
            },
            delete_variant_modal_error_snackbar_title:{
                fr: "Erreur",
                en: "Error",
            },
            delete_variant_modal_error_snackbar_text:{
                fr: "Une erreur est survenue lors de la suppression du trajet",
                en: "An error occurred while deleting the ride",
            },

            // EDIT VARIANT DIALOG

            edit_variant_modal_title:{
                fr: "Modifier le trajet",
                en: "Edit ride :",
            },
            variant_edition_text:{
                fr: "Modification du trajet en cours ...",
                en: "Ride edition in progress ...",
            },

            edit_variant_departure_field_label:{
                fr: "Départ",
                en: "Departure",
            },
            edit_variant_departure_field_form_helper_text:{
                fr: "Obligatoire",
                en: "Mandatory",
            },
            edit_variant_arrival_field_label:{
                fr: "Arrivée",
                en: "Arrival",
            },
            edit_variant_arrival_field_form_helper_text:{
                fr: "Obligatoire",
                en: "Mandatory",
            },
            edit_variant_description_field_label:{
                fr: "Description",
                en: "Description",
            },
            edit_variant_type_field_label:{
                fr: "Type de trajet *",
                en: "Ride type *",
            },
            edit_variant_type_field_header:{
                fr: "Choisir un type",
                en: "Choose a type",
            },
            edit_variant_type_field_form_helper_text:{
                fr: "Obligatoire",
                en: "Mandatory",
            },

            edit_variant_modal_cancel_button_text:{
                fr: "Annuler",
                en: "Cancel",
            },
            edit_variant_file_modal_validate_button_text:{
                fr: "Modifier",
                en: "Edit",
            },

            edit_variant_modal_success_snackbar_title:{
                fr: "Confirmation",
                en: "Confirmation",
            },
            edit_variant_modal_success_snackbar_text:{
                fr: "Le trajet a été modifié avec succès",
                en: "The ride was successfully edited",
            },
            edit_variant_modal_error_snackbar_title:{
                fr: "Erreur",
                en: "Error",
            },
            edit_variant_modal_error_snackbar_text:{
                fr: "Une erreur est survenue lors de la modification du trajet",
                en: "An error occurred while editing the ride",
            },

            // CREATE VARIANT DIALOG

            create_variant_modal_title:{
                fr: "Créer un trajet",
                en: "Create a ride :",
            },
            variant_creation_text:{
                fr: "Création du trajet en cours ...",
                en: "Ride creation in progress ...",
            },

            create_variant_departure_field_label:{
                fr: "Départ",
                en: "Departure",
            },
            create_variant_departure_field_form_helper_text:{
                fr: "Obligatoire",
                en: "Mandatory",
            },
            create_variant_arrival_field_label:{
                fr: "Arrivée",
                en: "Arrival",
            },
            create_variant_arrival_field_form_helper_text:{
                fr: "Obligatoire",
                en: "Mandatory",
            },
            create_variant_description_field_label:{
                fr: "Description",
                en: "Description",
            },
            create_variant_type_field_label:{
                fr: "Type de trajet *",
                en: "Ride type *",
            },
            create_variant_type_field_header:{
                fr: "Choisir un type",
                en: "Choose a type",
            },
            create_variant_type_field_form_helper_text:{
                fr: "Obligatoire",
                en: "Mandatory",
            },

            create_variant_modal_cancel_button_text:{
                fr: "Annuler",
                en: "Cancel",
            },
            create_variant_file_modal_validate_button_text:{
                fr: "Ajouter",
                en: "Edit",
            },

            create_variant_modal_success_snackbar_title:{
                fr: "Confirmation",
                en: "Confirmation",
            },
            create_variant_modal_success_snackbar_text:{
                fr: "Le trajet a été créé avec succès",
                en: "The ride was successfully created",
            },
            create_variant_modal_error_snackbar_title:{
                fr: "Erreur",
                en: "Error",
            },
            create_variant_modal_error_snackbar_text:{
                fr: "Une erreur est survenue lors de la création du trajet",
                en: "An error occurred while creating the ride",
            },

            // NEW VARIANT MAP
            new_variant_map_departure_text:{
                fr: "Placer le point de départ",
                en: "Place the departure location",
            },
            new_variant_map_arrival_text:{
                fr: "Placer le point d'arrivée",
                en: "Place the arrival location",
            },
            new_variant_searchbox_placeholder:{
                fr: "Centrer la carte sur une position",
                en: "Center the map on a position",
            },


            // ARCHIVE VARIANT DIALOG

            archive_variant_modal_title:{
                fr: "Archiver le trajet",
                en: "Archive ride :",
            },
            variant_archiving_text:{
                fr: "Archivage du trajet en cours ...",
                en: "Ride archiving in progress ...",
            },
            variant_archiving_warning_text:{
                fr: "En continuant, vous allez archiver le trajet: ",
                en: "By proceeding, you will archive the ride: ",
            },
            variant_archiving_warning_text_separator:{
                fr: " vers ",
                en: " to ",
            },
            variant_archiving_warning_bold_text:{
                fr: "Une fois archivé, ce trajet ne sera plus visible par les utilisateurs des applications mobiles",
                en: "Once archived, this ride will no longer be visible on the mobile apps",
            },
            archive_variant_modal_cancel_button_text:{
                fr: "Annuler",
                en: "Cancel",
            },
            archive_variant_file_modal_validate_button_text:{
                fr: "Archiver",
                en: "Archive",
            },
            archive_variant_modal_success_snackbar_title:{
                fr: "Confirmation",
                en: "Confirmation",
            },
            archive_variant_modal_success_snackbar_text:{
                fr: "Le trajet a été archivé avec succès",
                en: "The ride was successfully archived",
            },
            archive_variant_modal_error_snackbar_title:{
                fr: "Erreur",
                en: "Error",
            },
            archive_variant_modal_error_snackbar_text:{
                fr: "Une erreur est survenue lors de l'archivage du trajet",
                en: "An error occurred while archiving the ride",
            },

            // ARCHIVE VARIANT DIALOG

            unarchive_variant_modal_title:{
                fr: "Désarchiver le trajet",
                en: "Unarchive ride :",
            },
            variant_unarchiving_text:{
                fr: "Désarchivage du trajet en cours ...",
                en: "Ride unarchiving in progress ...",
            },
            variant_unarchiving_warning_text:{
                fr: "En continuant, vous allez désarchiver le trajet: ",
                en: "By proceeding, you will unarchive the ride: ",
            },
            variant_unarchiving_warning_text_separator:{
                fr: " vers ",
                en: " to ",
            },
            variant_unarchiving_warning_bold_text:{
                fr: "Une fois désarchivé, ce trajet sera de nouveau visible par les utilisateurs des applications mobiles",
                en: "Once unarchived, this ride will be visible on the mobile apps again",
            },
            unarchive_variant_modal_cancel_button_text:{
                fr: "Annuler",
                en: "Cancel",
            },
            unarchive_variant_file_modal_validate_button_text:{
                fr: "Désarchiver",
                en: "Unarchive",
            },
            unarchive_variant_modal_success_snackbar_title:{
                fr: "Confirmation",
                en: "Confirmation",
            },
            unarchive_variant_modal_success_snackbar_text:{
                fr: "Le trajet a été désarchivé avec succès",
                en: "The ride was successfully unarchived",
            },
            unarchive_variant_modal_error_snackbar_title:{
                fr: "Erreur",
                en: "Error",
            },
            unarchive_variant_modal_error_snackbar_text:{
                fr: "Une erreur est survenue lors du désarchivage du trajet",
                en: "An error occurred while unarchiving the ride",
            },

            //DUPLICATE VARIANT

            duplicate_variant_success_snackbar_title:{
                fr: "Confirmation",
                en: "Confirmation",
            },
            duplicate_variant_success_snackbar_text:{
                fr: "Le trajet a été dupliqué avec succès",
                en: "The ride was successfully duplicated",
            },
            duplicate_variant_error_snackbar_title:{
                fr: "Erreur",
                en: "Error",
            },
            duplicate_variant_error_snackbar_text:{
                fr: "Une erreur est survenue lors de la duplication du trajet",
                en: "An error occurred while duplicating the ride",
            },

        }
    }
}
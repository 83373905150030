
import {GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps";
import React from "react";

const GoogleMapsWrapper = withScriptjs(withGoogleMap(props => {
    const {onMapMounted, ...otherProps} = props;
    return <GoogleMap {...otherProps} ref={c => {
        onMapMounted && onMapMounted(c)
    }}>{props.children}</GoogleMap>
}));

export default GoogleMapsWrapper;
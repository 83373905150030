import {Track} from "./Track";
import {Variant} from "./Variant";
import {POI} from "./POI";
export class Ride {
    id:number;
    direction:string;
    outward:boolean;
    backward:boolean;
    tracks:Array<Track>;
    pois:Array<POI>;
    variant:Variant;

    //Front variables
    hovered:boolean=false;

    constructor(id: number, direction: string) {
        this.id = id;
        this.tracks = [];
        this.direction = direction;
        this.backward = (direction === "return");
        this.outward = !this.backward;
    }

    /**
     * Mapping the rides
     * @param rawArray
     * @returns {Array}
     */
    static mapRides(rawArray:any) : Array<Ride> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Ride.mapRide(o));
            }
        } catch(err) {
            console.log(err);
            return result;
        }
        return result;
    }

    prepare(variant:Variant) {
        if(variant === null || variant.id === null || variant.id <= 0) {
            console.error("Ride.prepare() : provided variant had no correct ID", variant);
        }
        return {
            direction : this.direction,
            variant : variant.id
        }
    }

    static create(direction:string){
        return new Ride(null, direction);
    }


    static mapRide(rawObject:any) : Ride {
        return new Ride(rawObject.id, rawObject.direction);
    }

    toString() {
        if (this.variant) {
            if(this.backward) {
                return this.variant.arrival + " <> " + this.variant.departure;
            }
            else {
                return this.variant.departure + " <> " + this.variant.arrival;
            }
        }
        return "inconnu";
    }

}

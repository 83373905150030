import {LatLngLiteral} from "../interfaces/LatLngLitteral";
import type {LatLngVectorLiteral} from "../interfaces/LatLngVectorLitteral";
import {LatLngBounds} from "../entities/LatLngBounds";

export class Geodesic {
  static computeDistanceBetween(p1: LatLngLiteral, p2: LatLngLiteral) {
    let lat1 = p1.lat;
    let lng1 = p1.lng;
    let lat2 = p2.lat;
    let lng2 = p2.lng;

    let pi80 = Math.PI / 180;
    lat1 *= pi80;
    lng1 *= pi80;
    lat2 *= pi80;
    lng2 *= pi80;

    /**
     * originally used value : 6372.797
     * Since we're operating around the 48" lat value, I selected a more accurate value here :
     * https://rechneronline.de/earth-radius/
     *
     * @type {number}
     */
    let r = 6366.371;
    let dlat = lat2 - lat1;
    let dlng = lng2 - lng1;
    let a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let km = r * c;

    return (km * 1000)
  }

  static projectOnVector(p:LatLngLiteral, v:LatLngVectorLiteral) : LatLngLiteral {
    let result = null;
    if (v.from.lat === v.to.lat) {
      result = {lat : v.from.lat, lng : p.lng };
    }
    else if (v.from.lng === v.to.lng) {
      result = { lat : p.lat, lng : v.from.lng };
    }
    else {
      const acroissement = (v.to.lng - v.from.lng) / (v.to.lat - v.from.lat);
      const origine = v.from.lng - v.from.lat * acroissement;
      const perpAcroissement = -1 / acroissement;
      const perpOrigine = p.lng - p.lat * perpAcroissement;
      const xProj = (perpOrigine - origine) / (acroissement - perpAcroissement);
      const yProj = perpAcroissement * xProj + perpOrigine;
      result = { lat : xProj, lng : yProj };
    }
    return result;
  }


  /**
   * Returns true only if the point is on vector
   * @param p
   * @param v
   * @returns {boolean}
   */
  static isOnVector(p:LatLngLiteral, v:LatLngVectorLiteral) : boolean {
      const divergentVector = {from : v.from, to: p};
      if(Geodesic.areCoLinear(divergentVector, v)) {
        const sp = Geodesic.scalarProduct(divergentVector,v);
        return (sp <= 1 && sp >= 0);
      }
      return false;
  }


  /**
   * Returns true only if the point is  before the vector
   * @param p
   * @param v
   * @returns {boolean}
   */
  static isBeforeVector(p:LatLngLiteral, v:LatLngVectorLiteral) : boolean {
      const divergentVector = {from : v.from, to: p};
      if(Geodesic.areCoLinear(divergentVector, v)) {
        const sp = Geodesic.scalarProduct(divergentVector,v);
        return sp < 0;
      }
      return false;
  }


  /**
   * Returns true only if the point is after the vector
   * @param p
   * @param v
   * @returns {boolean}
   */
  static isAfterVector(p:LatLngLiteral, v:LatLngVectorLiteral) : boolean {
      const divergentVector = {from : v.from, to: p};
      if(Geodesic.areCoLinear(divergentVector, v)) {
        const sp = Geodesic.scalarProduct(divergentVector,v);
        return sp > 1;
      }
      return false;
  }

  // static computeHeading(v: LatLngVectorLiteral) {
  //     console.log('I"m google')
  //   // eslint-disable-next-line
  //   return google.maps.geometry.spherical.computeHeading({lat: () => { return v.from.lat}, lng:() => { return v.from.lng}},{lat: () => {return v.to.lat}, lng: () => { return v.to.lng }})
  //
  // }

  static computeHeading(v:LatLngVectorLiteral) : number {
      let fromLat = Geodesic.toRadians(v.from.lat);
      let fromLng = Geodesic.toRadians(v.from.lng);
      let toLat = Geodesic.toRadians(v.to.lat);
      let toLng = Geodesic.toRadians((v.to.lng));
      let dLng = toLng - fromLng;
      let heading = Math.atan2(Math.sin(dLng) * Math.cos(toLat), Math.cos(fromLat) * Math.sin(toLat) - Math.sin(fromLat) * Math.cos(toLat) * Math.cos(dLng));
      return Geodesic.wrap(Geodesic.toDegrees(heading), -180, 180);
  }

  /**
   *
   * @param reference the reference vector to project the target on
   * @param target the targetted vector to project on the reference
   * @returns {number}
   */
  static scalarProduct(target:LatLngVectorLiteral, reference: LatLngVectorLiteral) {
    let a = {
      x: reference.to.lat - reference.from.lat,
      y: reference.to.lng - reference.from.lng
    };
    let b = {
      x: target.to.lat - target.from.lat,
      y: target.to.lng - target.from.lng
    };
    let scalarProduct = a.x * b.x + a.y * b.y;
    let aNorm = Math.sqrt(a.x * a.x + a.y * a.y);
    return scalarProduct / (aNorm * aNorm);
  }

  /**
   * returns wether the vectors are co linear (tolerance 10^-2)
   *
   * @param v1
   * @param v2
   * @returns {number}
   */
  static areCoLinear(v1:LatLngVectorLiteral, v2: LatLngVectorLiteral) : boolean {
    // let h1 = Geodesic.computeHeading(v1);
    // let h2 = Geodesic.computeHeading(v2);
    // let deltah = h2-h1;
    // console.log("deltah = " + deltah + (Math.abs(deltah < 0.01) ? "Colinear" : "not colinear"));


    let a = {
      x: v1.to.lat - v1.from.lat,
      y: v1.to.lng - v1.from.lng
    };
    let b = {
      x: v2.to.lat - v2.from.lat,
      y: v2.to.lng - v2.from.lng
    };

    if((a.x === 0 && b.x === 0) || (a.y === 0 && b.y === 0)) {
      return true; //a & b are vertical or horizontal
    } else if(b.x === 0 || b.y === 0) {
      return false; //b is vertical or horizontal, but as is from another kind.
    }
    const k1 = a.x/b.x;
    const k2 = a.y/b.y;


    let res =  (Math.round(k2*1000000) === Math.round(k1*1000000));
    return res;
  }


  static toRadians(degrees) {
    return degrees * Math.PI / 180;
  };

  static toDegrees(radians) {
    return radians * 180 / Math.PI;
  };

  static mod(x, m) {
    return ((x % m) + m) % m;
  };

  static wrap(n, min, max) {
    return (n >= min && n < max) ? n : (Geodesic.mod(n - min, max - min) + min);
  };

    static inBounds(bounds: LatLngBounds, point : LatLngLiteral) {
        return !(point.lat >= bounds.minLat && point.lat <= bounds.maxLat && point.lng >= bounds.minLng && point.lng <= bounds.maxLng);
    }
}

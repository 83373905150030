import React, {Component} from 'react';
import "./drivingRuleView.css";
import {FlexnavDriver, FlexnavEngineProperties} from "hubup-map-engine";

import Navbar from "../../sharedCored/components/Navbar";
import {MapParameters} from "../MapParameters";
import GoogleMapsWrapper from "../../sharedCored/GoogleMapsWrapper";
import {Tools} from "../../sharedCored/Tools";
import {GeoDriveAPI} from "../API/GeoDriveApi";
import {InfoWindow, Marker, Polyline} from "react-google-maps";

import {Button, IconButton} from "@material-ui/core";

import stop_pin from './../../../assets/maps_assets/icon_bus_stop_v2.png'
import tbt_pin from '../../../assets/maps_assets/tbt_pin - Copy.png'
import start_direction_pin from '../../../assets/maps_assets/start_duty_pin.png'
import end_direction_pin from '../../../assets/maps_assets/end_duty_pin.png'
import warning_pin from '../../../assets/maps_assets/warning_500_orange.png'
import {Direction} from "../entities/Direction";
import Icon from "@material-ui/core/Icon";
import {LatLngBounds} from "../../../geo-utilities/entities/LatLngBounds";
import {Encoder} from "../../../geo-utilities/others/Encoder";
import MapInstructions from "../instruction/MapInstructions";
import {TrackPreviewInfoAPI} from "../instruction/API/TrackPreviewInfoAPI";
import Slider from "@material-ui/core/Slider";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PdfInstructions from "../instruction/pdfInstructions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {FlexnavLogApi} from "../API/FlexnavLogApi";

class DrivingRuleView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            steps: [],
            isLoaded: false,
            variant: [],
            hoveredStop: null,
            hoveredDirection: null,
            hoveredDangerZone: null,
            hoveredTunnelZone: null,
            hoveredReRoutingZone: null,
            hoveredReRoutingPathDirection: null,
            dataSet: null,
            bounds: null,
            trackPreviewInfo: null,
            valueOpacity: 10,
            isSliderOpacityOpen: false,

            menuDisplayed: true,
        }
    }

    _driver: FlexnavDriver;
    _mapRef;

    componentDidMount(): void {
        this.getDataFromGeoDriveAPI()
        this.getStepsInstructions()
    }


    onMapMounted(map) {
        this._mapRef = map;
    }

    async getDataFromGeoDriveAPI() {
        try {
            const dataSet = await GeoDriveAPI.getTrackEditDataRaw(this.props.match.params.trackId)

            this._driver = new FlexnavDriver();
            let parameters = {
                autoAdjustDensity: false,
                desiredDensity: 20,
                disable_away_check: true,
                singleSubEngine: true
            };
            await this._driver.initFromDataset(dataSet, parameters);
            let engineProperties: FlexnavEngineProperties = this._driver.getActiveEngineProperties()

            // Calculate rerouting positions
            if (engineProperties && engineProperties.reRoutingPaths) {
                for (let i = 0; i < engineProperties.reRoutingPaths.length; i++) {
                    let positions = [];
                    positions = Encoder.decodePolyline(engineProperties.reRoutingPaths[i].encodedPolyLine, 6);
                    engineProperties.reRoutingPaths[i].positions = positions
                }
                // let positionsReRoutingPaths = Encoder.decodePolyline( engineProperties.reRoutingPaths[i].encodedPolyLine, 6);
                // calculatePositionsReRoutingPaths.push(positionsReRoutingPaths)
            }
            // engineProperties.reRoutingPaths.positions = calculatePositionsReRoutingPaths
            // End rerouting positions

            // console.log(engineProperties, 'engine')
            // console.log(dataSet, 'dataset')

            this.postFlexnavLog(dataSet);

            this.setState({
                dataSet,
                variant: engineProperties,
            }, () => this.fitMapToBounds(engineProperties.computedBounds))

        } catch (error) {
            console.warn(error)
        }
    }

    postFlexnavLog(dataSet) {
        let payload = {
            mode: "SHARED_TERMINAL",
            variantId: dataSet.variant.id,
            routeId: dataSet.route.id,
            session: null,
        }
        FlexnavLogApi.postFlexnavLog(payload)
            .then(() => {
                console.log("Flexnav log posted")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    async getStepsInstructions() {

        const data = await TrackPreviewInfoAPI.getPreviewInfo(this.props.match.params.trackId);
        if (data) {
            TrackPreviewInfoAPI.getDistanceToNextDirection(data);
            TrackPreviewInfoAPI.getDistanceToNextStops(data)
        }

        if (data.trackPreviewInfoFiles) {
            console.log(data, 'preview info')
            for (let file of data.trackPreviewInfoFiles) {
                // file.downloadRoute = global.proxyCompany.serverUrl + FOSRouter.getRoute('api_track_preview_get_file_content', {file: file.id}) + "?bearer=" + global.jwt;
                file.downloadRoute = global.proxyCompany.serverUrl + `/shared/${file.id}/content?bearer=${global.jwt}`
            }
        }
        this.setState({
            trackPreviewInfo: data
        })
    }

    getTrackLength(distance) {
        if (distance > 1000) {
            let kilometers = Math.floor(distance / 100) / 10;
            return kilometers + "km"
        } else {
            return Math.floor(distance) + "m"
        }
    }

    fitMapToBounds(bounds) {
        if (bounds) {
            try {
                this._mapRef.fitBounds(new LatLngBounds({lat: bounds.minLat, lng: bounds.minLng}, {
                    lat: bounds.maxLat,
                    lng: bounds.maxLng
                }).toGoogleLatLngBouldsLiteral());
            } catch (error) {
                console.log("Access to map reference denied: Google map is not mounted yet");
            }
        }
    }

    closeSideMenu() {
        try {
            const instructionsMenu = document.getElementById('instructions-menu')
            if (instructionsMenu) {
                if (instructionsMenu.classList.contains('instructions-menu-visible')) {
                    instructionsMenu.classList.remove('instructions-menu-visible')
                } else {
                    instructionsMenu.classList.add('instructions-menu-visible')

                }
            }
        } catch (err) {

        }
        // this.setState({menuDisplayed: !this.state.menuDisplayed})
    }

    handleChangeOpacity(event, newValue) {
        this.setState({valueOpacity: newValue})
    }

    render() {
        return (
            <div style={{height: '100%', width: '100%'}}>
                <Navbar title={"Détail de la variante"}/>

                <div style={{
                    height: 'calc(100vh - 60px)',
                    width: '100%',
                    display: "flex",
                    flexDirection: "row",
                    overflow: 'hidden',
                    position: "relative"
                }}>

                    <div
                        // className={this.state.menuDisplayed && this.state.trackPreviewInfo ? "map-wrapper map-wrapper-reduced" : "map-wrapper"}>
                        className={"map-wrapper"}>

                        {this.renderSumUp()}


                        <GoogleMapsWrapper
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyDS2rmKq2vRbYSbzYa11tqdsw2GdP6Ka2E`}
                            loadingElement={<div style={{height: `100%`, width: '100%'}}/>}
                            containerElement={<div className='google-map-container-element'/>}
                            mapElement={<div style={{height: `100%`, width: '100%'}}/>}
                            onMapMounted={(map) => {
                                this.onMapMounted(map)
                            }}
                            defaultZoom={15}
                            defaultCenter={{lat: Tools.getDefaultLatLng().lat, lng: Tools.getDefaultLatLng().lng}}
                            defaultOptions={{
                                mapTypeControl: true,
                                streetViewControl: true,
                                fullscreenControl: true,
                                zoomControl: false,
                                styles: MapParameters.ratp_light
                            }}
                        >

                            <template>

                                {/*Polyline*/}
                                {this.renderMapWrapperPolyline()}


                                {/*Marker*/}
                                {this.renderMapWrapperStops()}
                                {this.renderMapWrapperDirections()}
                                {this.renderMapWrapperReRoutingPath()}

                            </template>
                            {this.renderDropdownMenu()}
                            {this.renderMapLegend()}
                            {this.renderSliderOpacity()}
                            <div className={'display-instructions-button-mobile'}>
                                {this.renderDisplayInstructionsButton()}
                            </div>
                        </GoogleMapsWrapper>

                    </div>

                    {/*<PDFDownloadLink*/}
                    {/*    document={<PdfInstructions previewInfo={this.state.trackPreviewInfo}*/}
                    {/*                               route={this.state.dataSet && this.state.dataSet.route}*/}
                    {/*                               variant={this.state.dataSet && this.state.dataSet.variant}/>}*/}
                    {/*    fileName={`Consignes de parcours ${this.state.dataSet && this.state.dataSet.route.number}.pdf`}*/}
                    {/*    style={{*/}
                    {/*        bottom: 0,*/}
                    {/*        position: 'absolute',*/}
                    {/*        margin: '1em',*/}
                    {/*        right: 0,*/}
                    {/*        zIndex: 0,*/}
                    {/*        display: this.state.menuDisplayed && this.state.trackPreviewInfo ? 'flex' : 'none',*/}
                    {/*        width: '50px',*/}
                    {/*        height: '50px',*/}
                    {/*        borderRadius: '50%',*/}
                    {/*        background: '#1972F5',*/}
                    {/*        boxShadow: '0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)',*/}
                    {/*        cursor: 'pointer',*/}
                    {/*        justifyContent: 'center',*/}
                    {/*        alignItems: 'center',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {({loading}) => (loading*/}
                    {/*        ? <CircularProgress/>*/}
                    {/*        :*/}
                    {/*        <Tooltip title={"Télécharger les consignes de parcours en pdf"} placement={"left"}>*/}
                    {/*            <Icon style={{*/}
                    {/*                color: '#FFF',*/}
                    {/*                fontSize: 22,*/}
                    {/*                display: 'inline-block',*/}
                    {/*                textAlign: 'center'*/}
                    {/*            }}>*/}
                    {/*                cloud_download*/}
                    {/*            </Icon>*/}
                    {/*        </Tooltip>)}*/}
                    {/*</PDFDownloadLink>*/}
                    <MapInstructions data={this.state.trackPreviewInfo}
                                     route={this.state.dataSet && this.state.dataSet.route}
                                     variant={this.state.dataSet && this.state.dataSet.variant}
                                     closeSideMenu={() => this.closeSideMenu()}
                    />
                </div>

            </div>
        );
    }

    renderMapWrapperPolyline() {

        let lineSymbol = {
            path: 'M 1,0 -1,0 0,-1 z',
            strokeColor: '#222222',
            fillColor: "#FFFFFF",
            fillOpacity: 0.8,
            strokeWeight: 2
        };


        return (
            <template>
                <Polyline
                    options={{
                        strokeColor: "#3F98F4",
                        strokeWeight: 8,
                        zIndex: 3,
                        strokeOpacity: this.state.valueOpacity / 10,
                        icons: [{icon: lineSymbol, offset: '0', repeat: '200px'}],
                    }}
                    path={this.state.variant && this.state.variant.positions}

                />
                <Polyline
                    options={{
                        strokeColor: "#222222",
                        strokeWeight: 10,
                        zIndex: 1,
                        strokeOpacity: this.state.valueOpacity / 10,
                    }}
                    path={this.state.variant && this.state.variant.positions}
                />

                {/*DangerZone*/}
                {this.state.variant && this.state.variant.dangerZoneLinearElements && this.state.variant.dangerZoneLinearElements.map(dangerZone => {
                    return (
                        <template key={dangerZone.dangerZone.id}>
                            <Polyline
                                options={{
                                    strokeColor: "#F49900",
                                    strokeWeight: 8,
                                    zIndex: 3,
                                    strokeOpacity: this.state.valueOpacity / 10,
                                }}
                                path={dangerZone.positions}
                                onMouseOver={(event) => this.hoverDangerZone(event, dangerZone)}
                                onMouseOut={() => {
                                    this.setState({hoveredDangerZone: null})
                                }}
                            />

                            {this.state.hoveredDangerZone && this.state.hoveredDangerZone.dangerZone.id === dangerZone.dangerZone.id &&
                                <Marker
                                    icon={{url: stop_pin, scaledSize: {width: 0, height: 0}}}
                                    key={dangerZone.dangerZone.id + 'info'}
                                    title={dangerZone.dangerZone.textToDisplay}
                                    position={{
                                        lat: this.state.hoveredDangerZone.lat,
                                        lng: this.state.hoveredDangerZone.lng
                                    }}>
                                    >
                                    <InfoWindow style={{padding: 0, boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)'}}>
                                        <div style={{
                                            minHeight: 50,
                                            maxWidth: 200,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            borderRadius: 8
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 30,
                                                marginRight: 10
                                            }}>
                                                <img src={warning_pin} alt={dangerZone.dangerZone.textToDisplay}
                                                     style={{height: 30, width: 30}}/>
                                            </div>
                                            <span style={{
                                                fontWeight: "bold",
                                                color: '#222222',
                                                textAlign: "left"
                                            }}>{dangerZone.dangerZone.textToDisplay}</span>

                                        </div>
                                    </InfoWindow>
                                </Marker>
                            }
                        </template>
                    )
                })}
                {/*End danger zone*/}

                {/*TunnelZone*/}
                {this.state.variant && this.state.variant.tunnelZoneLinearElements && this.state.variant.tunnelZoneLinearElements.map(tunnelZone => {
                    return (
                        <template key={tunnelZone.tunnelZone.id}>
                            <Polyline
                                options={{
                                    strokeColor: "#30E180",
                                    strokeWeight: 5,
                                    zIndex: 3,
                                    strokeOpacity: this.state.valueOpacity / 10,
                                }}
                                path={tunnelZone.positions}
                                onMouseOver={(event) => this.hoveredTunnelZone(event, tunnelZone)}
                                onMouseOut={() => {
                                    this.setState({hoveredTunnelZone: null})
                                }}
                            />
                            {this.state.hoveredTunnelZone && this.state.hoveredTunnelZone.tunnelZone.id === tunnelZone.tunnelZone.id &&
                                <Marker
                                    icon={{url: stop_pin, scaledSize: {width: 0, height: 0}}}
                                    key={tunnelZone.tunnelZone.id + 'info'}
                                    title={tunnelZone.tunnelZone.textToDisplay}
                                    position={{
                                        lat: this.state.hoveredTunnelZone.lat,
                                        lng: this.state.hoveredTunnelZone.lng
                                    }}>
                                    >
                                    <InfoWindow style={{padding: 0, boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)'}}>
                                        <div style={{
                                            minHeight: 50,
                                            maxWidth: 200,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            borderRadius: 8
                                        }}>
                                            <span style={{
                                                fontWeight: "bold",
                                                color: '#222222',
                                                textAlign: "left"
                                            }}>{tunnelZone.tunnelZone.textToDisplay}</span>

                                        </div>
                                    </InfoWindow>
                                </Marker>
                            }
                        </template>
                    )
                })}
                {/*tunnelZone*/}

                {/*reRoutingPaths*/}
                {this.state.variant && this.state.variant.reRoutingPaths && this.state.variant.reRoutingPaths.map(reRoutingPaths => {
                    return (
                        <template key={reRoutingPaths.id}>
                            <Polyline
                                options={{
                                    strokeColor: "#ae69c1",
                                    strokeWeight: 8,
                                    zIndex: 3,
                                    strokeOpacity: this.state.valueOpacity / 10,
                                    icons: [{icon: lineSymbol, offset: '0', repeat: '150px'}]
                                }}
                                path={reRoutingPaths.positions}
                                onMouseOver={(event) => this.hoveredReRoutingZone(event, reRoutingPaths)}
                                onMouseOut={() => {
                                    this.setState({hoveredReRoutingZone: null})
                                }}
                            />

                            <Polyline
                                options={{
                                    strokeColor: "#222222",
                                    strokeWeight: 10,
                                    zIndex: 1,
                                    strokeOpacity: this.state.valueOpacity / 10
                                }}
                                path={reRoutingPaths.positions}
                            />

                            {this.state.hoveredReRoutingZone && this.state.hoveredReRoutingZone.id === reRoutingPaths.id &&
                                <Marker
                                    icon={{url: stop_pin, scaledSize: {width: 0, height: 0}}}
                                    key={reRoutingPaths.id + 'info'}
                                    position={{
                                        lat: this.state.hoveredReRoutingZone.lat,
                                        lng: this.state.hoveredReRoutingZone.lng
                                    }}>
                                    >
                                    <InfoWindow style={{padding: 0, boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)'}}>
                                        <div style={{
                                            minHeight: 50,
                                            maxWidth: 200,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            borderRadius: 8
                                        }}>
                                            <span style={{fontWeight: "bold", color: '#222222', textAlign: "left"}}>Itinéraire de re-guidage</span>

                                        </div>
                                    </InfoWindow>
                                </Marker>
                            }
                        </template>
                    )
                })}
                {/*end reRoutingPaths*/}

            </template>
        )
    }


    renderMapWrapperStops() {
        return (
            <template>
                {this.state.variant && this.state.variant.stopsPunctualElements && this.state.variant.stopsPunctualElements.map((stop) => {
                    return (
                        <Marker key={"stop_" + stop.stop.id}
                                position={{
                                    lat: stop.projectedCP.latitude,
                                    lng: stop.projectedCP.longitude,
                                }}
                                icon={{url: stop_pin, scaledSize: {width: 40, height: 40}}}
                                onMouseOver={() => {
                                    this.setState({hoveredStop: stop})
                                }}
                                onClick={() => {
                                    this.setState({hoveredStop: stop})
                                }}
                                onMouseOut={() => {
                                    this.setState({hoveredStop: null})
                                }}
                                title={stop.name}
                        >

                            {/*Hover*/}
                            {this.state.hoveredStop && this.state.hoveredStop.stop.id === stop.stop.id &&
                                <InfoWindow style={{padding: 0, boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)'}}>
                                    <div style={{
                                        minHeight: 50,
                                        maxWidth: 200,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        borderRadius: 8
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 50
                                        }}>
                                            <img src={stop_pin} alt={stop.stop.name}
                                                 style={{height: 30}}/>
                                        </div>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: '#222222',
                                            textAlign: "left"
                                        }}>{stop.stop.name}</span>

                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                })}
            </template>
        )
    }

    renderMapWrapperDirections() {
        return (
            <template>
                {/*Start Icon*/}
                {
                    this.state.variant && this.state.variant.positions &&
                    <Marker icon={{url: start_direction_pin, scaledSize: {width: 95 / 3, height: 165 / 3}}}
                            position={{
                                lat: this.state.variant.positions[0].latitude,
                                lng: this.state.variant.positions[0].longitude
                            }}
                            clickable={false}/>
                }

                {this.state.variant && this.state.variant.directionsPunctualElements && this.state.variant.directionsPunctualElements.map((direction) => {
                    return (
                        <Marker key={"directions" + direction.direction.id}
                                position={{
                                    lat: direction.projectedCP.latitude,
                                    lng: direction.projectedCP.longitude,
                                }}
                                icon={{
                                    url: tbt_pin,
                                    scaledSize: {width: 20, height: 20},
                                    anchor: {x: 10, y: 10},
                                    flat: true
                                }}
                                onMouseOver={() => {
                                    this.setState({hoveredDirection: direction})
                                }}
                                onClick={() => {
                                    this.setState({hoveredDirection: direction})
                                }}
                                onMouseOut={() => {
                                    this.setState({hoveredDirection: null})
                                }}
                                title={direction.direction.textToDisplay}
                        >

                            {/*Hover*/}
                            {this.state.hoveredDirection && this.state.hoveredDirection.direction.id === direction.direction.id &&
                                <InfoWindow style={{padding: 0, boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)'}}>
                                    <div style={{
                                        minHeight: 50,
                                        maxWidth: 200,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        borderRadius: 8
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 40,
                                            marginRight: direction.direction.position > 0 && 10
                                        }}>
                                            <img
                                                src={Direction.getTBTIndicationByPosition(direction.direction.position).src}
                                                alt={direction.direction.textToDisplay}
                                                style={{height: 25}}/>
                                        </div>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: '#222222',
                                            textAlign: "left"
                                        }}>{direction.direction.textToDisplay}</span>

                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                })}

                {/*end Icon*/}
                {
                    this.state.variant && this.state.variant.positions &&
                    <Marker icon={{url: end_direction_pin, scaledSize: {width: 95 / 3, height: 165 / 3}}}
                            position={{
                                lat: this.state.variant.positions[this.state.variant.positions.length - 1].latitude,
                                lng: this.state.variant.positions[this.state.variant.positions.length - 1].longitude
                            }}
                            clickable={false}/>
                }

            </template>
        )
    }

    renderMapWrapperReRoutingPath() {
        return (
            <template>
                {this.state.variant && this.state.variant.reRoutingPaths && this.state.variant.reRoutingPaths.map((reRoutingPath) => {
                    return (
                        reRoutingPath.directions.map((reRoutingDirection) => {
                            return (
                                <Marker key={"reroutingPath" + reRoutingDirection.id}
                                        position={{
                                            lat: reRoutingDirection.latitude,
                                            lng: reRoutingDirection.longitude,
                                        }}
                                        icon={{
                                            url: tbt_pin,
                                            scaledSize: {width: 20, height: 20},
                                            anchor: {x: 10, y: 10},
                                            flat: true
                                        }}
                                        onMouseOver={() => {
                                            this.setState({hoveredReRoutingPathDirection: reRoutingDirection})
                                        }}
                                        onMouseOut={() => {
                                            this.setState({hoveredReRoutingPathDirection: null})
                                        }}
                                        title={reRoutingDirection.textToDisplay}
                                >

                                    {/*Hover*/}
                                    {this.state.hoveredReRoutingPathDirection && this.state.hoveredReRoutingPathDirection.id === reRoutingDirection.id &&
                                        <InfoWindow style={{padding: 0, boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)'}}>
                                            <div style={{
                                                minHeight: 50,
                                                maxWidth: 200,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                borderRadius: 8
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: 40,
                                                    marginRight: reRoutingDirection.position > 0 && 10
                                                }}>
                                                    <img
                                                        src={Direction.getTBTIndicationByPosition(reRoutingDirection.position).src}
                                                        alt={reRoutingDirection.textToDisplay}
                                                        style={{height: 25}}/>
                                                </div>
                                                <span style={{
                                                    fontWeight: "bold",
                                                    color: '#222222',
                                                    textAlign: "left"
                                                }}>{reRoutingDirection.textToDisplay}</span>

                                            </div>
                                        </InfoWindow>
                                    }
                                </Marker>
                            )
                        })
                    )
                })}
            </template>
        )
    }

    renderSumUp() {
        return (
            <div className="driving-rule-sumup-wrapper">
                {
                    this.state.dataSet ?
                        <div className={"driving-rule-sumup-content-wrapper"}>
                            <div className="driving-rule-sumup-wrapper-first-part">
                                <div style={{
                                    height: 24,
                                    minWidth: 24,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    backgroundColor: `#${this.state.dataSet.route.color}`,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 3,
                                    border: '1px solid #222222',
                                    display: "flex",
                                    marginRight: 10,
                                    wrap: "flex-wrap"
                                }}>
                            <span style={{color: '#FFFFFF', fontSize: 12}}
                                  className={"text-bordered"}>{this.state.dataSet.route.number}</span>
                                </div>

                                <div style={{
                                    // height: 20,
                                    paddingLeft: 8,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: 'center',
                                    borderRadius: 3,
                                    border: '1px solid #CCCCCC',
                                    backgroundColor: '#F4F4F4',
                                    overflow: "hidden"
                                }}>
                                    <span style={{color: '#222222', textAlign: "left", fontSize: 14}}>
                                        {this.state.dataSet.variant.departure.toUpperCase()}
                                    </span>
                                    <Icon style={{
                                        color: '#555555',
                                        fontSize: 14,
                                        marginLeft: 5,
                                        marginRight: 5
                                    }}>arrow_forward</Icon>
                                    <span style={{color: '#222222', textAlign: "left", fontSize: 14}}>
                                        {this.state.dataSet.variant.arrival.toUpperCase()}
                                    </span>

                                    <div style={{height: "100%", width: 1, backgroundColor: '#CCCCCC', marginLeft: 7}}/>

                                    <div style={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        paddingRight: 8
                                    }}>
                                        <Icon style={{
                                            color: '#555555',
                                            fontSize: 14,
                                            marginLeft: 5,
                                            marginRight: 5
                                        }}>timeline</Icon>
                                        <span style={{
                                            color: '#222222',
                                            textAlign: "left",
                                            fontSize: 14
                                        }}>{this.getTrackLength(this.state.variant.computedLength)}</span>
                                        <Icon style={{
                                            color: '#555555',
                                            fontSize: 14,
                                            marginLeft: 5,
                                            marginRight: 5
                                        }}>directions_bus</Icon>
                                        <span style={{
                                            color: '#222222',
                                            textAlign: "left",
                                            fontSize: 14
                                        }}>{this.state.variant.stops.length + " arrêts"}</span>
                                    </div>

                                </div>
                            </div>

                            <div className="driving-rule-sumup-wrapper-second-part">
                                <p className={"driving-rule-variant-description"}
                                   style={{width: "100%", textAlign: "left"}}>
                                    {this.state.dataSet.variant.description}
                                </p>
                            </div>
                            <div>

                            </div>
                            <div style={{flex: 1}}/>
                            <div className={'display-instructions-button-desktop'}>
                                {this.state.trackPreviewInfo &&
                                    this.renderDisplayInstructionsButton()
                                }
                            </div>

                        </div>
                        :
                        <div style={{
                            height: 30,
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: 'center',
                            paddingLeft: 8,
                            paddingTop: 3
                        }}>
                            <div style={{
                                height: 24,
                                minWidth: 24,
                                paddingLeft: 4,
                                paddingRight: 4,
                                backgroundColor: '#EFEFEF',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 3,
                                border: '1px solid #EFEFEF',
                                display: "flex",
                                marginRight: 10
                            }}>
                            </div>
                            <span style={{color: '#CCCCCC', textAlign: "left", fontSize: 14}}>
                {"Chargement en cours"}
                    </span>
                        </div>
                }
            </div>
        )
    }

    renderDisplayInstructionsButton = () => {
        if(this.state?.trackPreviewInfo){
            return (
                <Button variant={"contained"}
                        size={"small"}
                        style={{backgroundColor: '#2D323E', marginRight: 10}}
                        onClick={() => this.closeSideMenu()}>

                    <span style={{color: '#FFFFFF'}}>Consignes</span>

                </Button>
            )
        }

    }

    renderDropdownMenu() {
        return (
            <div className={"opacity-button-mobile"} onClick={() => this.handleToggleSliderOpacity()}>
                <IconButton>
                    <Icon style={{fontSize: 18, color: '#555555'}}>opacity</Icon>
                </IconButton>
            </div>
        )
    }

    handleToggleSliderOpacity = () => {
        try {
            let popupMenu = document.getElementById("opacity-slider-popup-menu")
            if (popupMenu) {
                if (popupMenu.classList.contains("popup-menu-visible")) {
                    popupMenu.classList.remove("popup-menu-visible")
                } else {
                    popupMenu.classList.add("popup-menu-visible")
                }
            }
        } catch (err) {
            console.log(err)
        }
        // this.setState({isSliderOpacityOpen: !this.state.isSliderOpacityOpen})
    }


    renderSliderOpacity() {
        return (
            <div className={"popup-menu"} id={'opacity-slider-popup-menu'}>
                <Icon style={{fontSize: 18, color: '#555555', marginRight: 4}}>opacity</Icon>
                <div style={{whiteSpace: "nowrap", marginRight: "6px"}}>
                    Transparence du tracé
                </div>
                <Slider
                    value={this.state.valueOpacity}
                    onChange={(event, newValue) => this.handleChangeOpacity(event, newValue)}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={10}
                    style={{width: 130}}
                />
            </div>
        )
    }

    renderMapLegend() {
        return (
            <div style={{
                width: '100%',
                position: "absolute",
                left: 0,
                bottom: 5,
                pointerEvents: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: 'center'
            }}>
                <div style={{
                    marginLeft:5,
                    marginRight:5,
                    padding:"10px",
                    minHeight:40,
                    minWidth: 150,
                    borderRadius:8,
                    border: "1px solid #CCCCCC",
                    backgroundColor: '#FAFAFA',
                    pointerEvents: "auto",
                    overflow: "hidden",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexWrap:"wrap"
                }}>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: 'center',
                        height: 20
                    }}>
                        <div style={{
                            width: 22,
                            height: 10,
                            border: "2px solid #222222",
                            backgroundColor: '#3F98F4',
                            marginRight: 5,
                            borderRadius: 10
                        }}/>
                        <span style={{color: '#222222', fontSize: 12, marginRight: 10}}>Tracé principal</span>

                        <div style={{
                            width: 22,
                            height: 10,
                            border: "2px solid #222222",
                            backgroundColor: '#f49900',
                            marginRight: 5,
                            borderRadius: 10
                        }}/>
                        <span style={{color: '#222222', fontSize: 12, marginRight: 10}}>Zone de danger</span>

                        <div style={{
                            width: 22,
                            height: 10,
                            border: "2px solid #222222",
                            backgroundColor: '#2cf463',
                            marginRight: 5,
                            borderRadius: 10
                        }}/>
                        <span style={{color: '#222222', fontSize: 12, marginRight: 10}}>Zone tunnel</span>

                        <div style={{
                            width: 22,
                            height: 10,
                            border: "2px solid #222222",
                            backgroundColor: '#b700f4',
                            marginRight: 5,
                            borderRadius: 10
                        }}/>
                        <span style={{color: '#222222', fontSize: 12, marginRight: 10}}>Re-guidage</span>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: 'center',
                        height: 20
                    }}>
                        <img src={tbt_pin} alt={"tbt_pin"} style={{height: 14, marginRight: 3}}/>
                        <span style={{color: '#222222', fontSize: 12, marginRight: 10}}>Indication de guidage</span>

                        <img src={stop_pin} alt={"stop_pin"} style={{height: 17, marginRight: 3}}/>
                        <span style={{color: '#222222', fontSize: 12, marginRight: 10}}>Arrêts</span>
                    </div>

                </div>
            </div>
        )
    }

    hoverDangerZone(event, dangerZone) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        if (lat && lng) {
            dangerZone.lat = lat
            dangerZone.lng = lng
        }
        this.setState({hoveredDangerZone: dangerZone})

    }

    hoveredTunnelZone(event, tunnelZone) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        if (lat && lng) {
            tunnelZone.lat = lat
            tunnelZone.lng = lng
        }
        this.setState({hoveredTunnelZone: tunnelZone})
    }

    hoveredReRoutingZone(event, reRoutingPaths) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        if (lat && lng) {
            reRoutingPaths.lat = lat
            reRoutingPaths.lng = lng
        }
        this.setState({hoveredReRoutingZone: reRoutingPaths})
    }
}

export default DrivingRuleView;

import {RESTManager} from "../../../sharedCored/api/RESTManager";

export class TrackPreviewInfoAPI {

    /**
     * Returns Preview Info of the track
     * @returns {Promise<TResult|T>}
     */
    static getPreviewInfo(trackId): Promise<any> {
        return new Promise((resolve, reject) => {
            RESTManager.get(`/shared/${trackId}/info`)
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    console.log("TrackPreviewInfoAPI :: getPreviewInfo() :: " + error);
                    reject("TrackPreviewInfoAPI ::getPreviewInfo() :: " + error);
                })
        })
    }

    static getDistanceToNextDirection (data) {
        let steps = data.trackPreviewInfoSteps.filter(el => el.dataType !== "dangerZoneStart" && el.dataType !== "dangerZoneEnd");
        for (let i = 0; i<steps.length-1; i++) {
            if (steps[i].dataType === "direction") {
                steps[i].distanceToNextDirection = steps[i+1].distanceFromOrigin - steps[i].distanceFromOrigin
            }
        }
        // add property to the last Item
        const directionArray = data.trackPreviewInfoSteps.filter(el => el.dataType === "direction");
        directionArray[directionArray.length-1].distanceToNextDirection = 0;
        return steps
    }

    static getDistanceToNextStops (data) {
        let stops = data.trackPreviewInfoSteps.filter(el => el.dataType === "stop");
        for (let i = 0; i<stops.length-1; i++) {
            stops[i].distanceToNextStop =  stops[i+1].distanceFromOrigin - stops[i].distanceFromOrigin;
        }
        // add property to the last Item
        const stopArray = data.trackPreviewInfoSteps.filter(el => el.dataType === "stop");
        stopArray[stopArray.length-1].distanceToNextStop = 0;
        return stops
    }

    static getPrettyDistance (dist){
        if (typeof dist === "undefined") {
            dist = 0
        }
        let unit = dist < 1000 ? "m" : "km";
        if(dist < 1000){
            let meters = Math.round(dist/10)*10;
            return meters + ' ' + unit;
        }
        else{
            let kilometers = Math.round(dist/100)/10;
            return kilometers + ' ' + unit
        }
    }

}
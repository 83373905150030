import {Ride} from "./Ride.js";
import {Subtrack} from "./Subtrack";


export class Track {
    ride:Ride;

    id:number;
    date:number;
    status:string;
    isNew:boolean;
    description:string;
    subtracks:Array<Subtrack>;
    trackPreviewInfos:any;

    //Front Variables

    displayed:boolean=false;


    constructor(id: number, date: number, status: string, description:string, isNew:boolean) {
        this.id = id;
        this.date = date;
        this.status = status;
        this.isNew = isNew;
        this.description = description;

    }

    prepare() {
        return {
          description : this.description
        };
    }

    static create() {
      return new Track(null, (new Date()).getTime()* 1000, "pending", "", true);
    }

    clone(){
        let track = new Track(this.id, this.date, this.status,this.description,this.isNew);

        if(this.subtracks){
            track.subtracks = this.subtracks;
        }

        return track;
    }

    getProductionSubtrack() {
        if(this.subtracks && this.subtracks.length) {
            for(let s of this.subtracks) {
                if(s.production) return s;
            }
        }
        return null;
    }

    hasProductionSubTrack() {
        console.log(this.getProductionSubtrack());
        return this.getProductionSubtrack() !== null;
    }



    /**
     * Mapping the rides
     * @param rawArray
     * @returns {Array}
     */
    static mapTracks(rawArray:any) : Array<Track> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Track.mapTrack(o));
            }
        } catch(err) {
            console.log("Error while decoding tracks " + err);
            return result;
        }
        return result;
    }

    static mapTrack(rawObject:any) : Track {
        let result =  new Track(rawObject.id, rawObject.date, rawObject.status, rawObject.description, rawObject.new);
        if(rawObject.trackPreviewInfos){
            result.trackPreviewInfos = rawObject.trackPreviewInfos
        }
        return result;
    }




}

import {Direction} from "./Direction";
import {Encoder} from "../../../geo-utilities/others/Encoder";


export class ReRoutingPath
{
    id:number;
    encodedPolyLine:string = "";
    description:string = "";
    directions:Array<Direction> = [];
    reRoutingType:string;

    //Front
    selected:boolean = false;
    startLat: number;
    startLng: number;

    static TYPE_NORMAL = "TYPE_NORMAL";


    static map(raw) : ReRoutingPath {
        let result = new ReRoutingPath();
        if("id" in raw) result.id = raw.id;
        if("encodedPolyLine" in raw) {
            result.encodedPolyLine = raw.encodedPolyLine;
            let decoded = result.decodePolyLine();
            result.startLat = decoded[0].lat;
            result.startLng = decoded[0].lng;
        }
        if("length" in raw) result.length = raw.length;
        if("description" in raw) result.description = raw.description;
        if("reRoutingType" in raw) {
            result.reRoutingType = raw.reRoutingType;
        }
        if("directions" in raw) {
            result.directions = Direction.mapDirections(raw.directions);
        }
        return result;
    }

    decodePolyLine() {
        return Encoder.decodePolyline(this.encodedPolyLine, 6);
    }

    static mapArray(raw) : Array<ReRoutingPath> {
        let result = [];
        for(let item of raw) {
            result.push(ReRoutingPath.map(item));
        }
        return result;
    }

    prepare() {
        return {
            encodedPolyLine : this.encodedPolyLine,
            description: this.description,
            reRoutingType: this.reRoutingType
        }
    }

}


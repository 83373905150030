

declare var google:any;

export class POI {
    id:number;
    name:string;
    snippet:string;
    tts:string;
    latitude:number;
    longitude:number;
    isOpen:boolean = false;
    isDraggable:boolean = false;

    //Front variables
    hovered:boolean=false;

    constructor(id: number, name: string, snippet: string, latitude: number, longitude: number, tts:string) {
        this.id = id;
        this.name = name;
        this.snippet = snippet;
        this.latitude = latitude;
        this.longitude = longitude;
        this.tts = tts;
    }

    isValid() {
        if(this.latitude !== +this.latitude) return false;
        if(this.longitude !== +this.longitude) return false;
        if(!this.name || this.name.length < 3) return false;
        if(!this.snippet || this.snippet.length < 2) return false;
        return true;

    }

    clone() : POI {
        return new POI(this.id, this.name, this.snippet, this.latitude, this.longitude);
    }

    getStandardMarkerAssetPath() {
        return "assets/map/iconbusstopmini.png";
    }
    getTransparentMarkerAssetPath() {
        return "assets/map/iconbusstopalphamimi.png";
    }
    getActiveMarkerAssetPath() {
        return "assets/map/isonbusstopbluemini.png";
    }

    getFlagStartAssetPath() {
        return "assets/map/departure.svg";
    }

    getFlagEndAssetPath() {
        return "assets/map/arrival.svg";
    }

    getUnfocusedPinPath(){
        return "assets/map/unfocused_pin.svg";
    }

    getFocusedPinPath(){
        return "assets/map/focused_pin.svg";
    }

    toLatLng() : LatLng {
        return new LatLng(this.latitude, this.longitude);
    }


    prepare() {
      return {
        name : this.name,
        snippet : this.snippet,
        lat: this.latitude,
        lng: this.longitude,
          tts: this.tts
      }
    }

    isNameValid() {
      return (this.name.length > 2);
    }

    isSnippetValid() {
      return (this.snippet.length > 1);
    }

    isLatitudeValid() {
      return !!this.latitude;
    }

    isLongitudeValid() {
      return !!this.longitude;
    }


  static create() {
    return new POI(null, "", "---", 0, 0, "");
  }

    static createMock() {
        return new POI(0, "", "", 0, 0, "");
    }



    static mapPOIs(rawArray:any) : Array<any> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(POI.mapPOI(o));
            }
        } catch(err) {
            console.log(err, rawArray);
            return result;
        }
        return result;
    }

    static mapPOI(rawObject:any) : any {
        return new POI(rawObject.id, rawObject.name, rawObject.snippet, rawObject.lat, rawObject.lng, "tts" in rawObject ? rawObject.tts : "");
    }

}

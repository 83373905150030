import axios from "axios"
import {ProxyCompany} from "../../sharedCored/entities/proxy/ProxyCompany";
import {User} from "../../sharedCored/entities/User";
import {FOSRouter} from "../../FOS/fos.router";
import CredentialsStorage from "../../sharedCored/entities/CredentialsStorage";

export class LoginApi {

    static getCompanyByTag(tag: string): Promise<ProxyCompany> {
        return new Promise((resolve, reject) => {
            const instance = axios.create({
                headers: {'Authorization': 'Basic aHVidXA6UXVlcmFuMXN0'}
            });

            let pc: any;

            instance.get(`https://proxy.hubup.fr/companies/` + tag + `/by/tag`)
                .then((response: any) => {
                    pc = response.data;
                    resolve(ProxyCompany.mapProxyCompany(pc));
                })
                .catch((error: any) => {
                    console.log(error);
                    reject("ProxyAPI::getCompanyByTag() :: " + error);
                })
        });
    }

    static  login(username:string, password:string, proxyCompany) : Promise<User> {

        return new Promise((resolve, reject) => {
            let jwt:string;
            let user:User;
            const company:ProxyCompany = proxyCompany;

            const unLogged = axios.create({
                baseURL: company.serverUrl,
                headers: {'content-type' : 'application/json'}
            });

            let logged = null;
            unLogged.post(FOSRouter.getRoute("api_login_check"), { _username: username, _password: password})
                .then((response:any) => {
                    jwt = response.data.token;
                    // console.log("Received JWT : " + jwt);
                    logged = axios.create({
                        baseURL: company.serverUrl,
                        headers: {'content-type' : 'application/json', 'Authorization' : 'Bearer ' + jwt }
                    });

                    return logged.get("/shared/self")
                })
                .catch((error:any) =>  {
                    console.log("UserAPI::login() : Error in get_user - ", error);
                    reject();
                })
                .then((result:any) => {
                    user = User.mapUser(result.data);
                    user.jwt = jwt;

                    global.jwt = jwt;
                    // LightAPI.createCookie('jwt', jwt,7);
                    CredentialsStorage.storeJWT(JSON.stringify(jwt))
                    user.abstractRoles = [];
                    resolve(user);
                })
                .catch((error:any) =>  {
                    console.log("UserAPI::login() : Error 400 - ", error);
                    reject(null);
                })
        });
    }

    // async login(username, password) {
    //     debugger
    //     try {
    //         let jwt: string;
    //         let user: User;
    //         const company: ProxyCompany = await CredentialsStorage.getProxyCompany();
    //
    //         const unLogged = axios.create({
    //             baseURL: company.serverUrl,
    //             headers: {'content-type': 'application/json'}
    //         });
    //
    //         let logged = null;
    //
    //         try {
    //             const data = await unLogged.post(FOSRouter.getRoute("api_login_check"), {_username: username, _password: password})
    //
    //             jwt = data.token;
    //             logged = axios.create({
    //                 baseURL: company.serverUrl,
    //                 headers: {'content-type': 'application/json', 'Authorization': 'Bearer ' + jwt}
    //             });
    //             try {
    //                 const result = await logged.get("/shared/self")
    //                 user = User.mapUser(result.data);
    //                 user.jwt = jwt;
    //
    //                 global.jwt = jwt;
    //                 // LightAPI.createCookie('jwt', jwt,7);
    //                 CredentialsStorage.storeJWT(JSON.stringify(jwt))
    //                 user.abstractRoles = [];
    //             } catch (error) {
    //                 console.warn(error)
    //                 console.log("UserAPI::login() : Error 400 - ", error);
    //             }
    //
    //
    //         } catch (e) {
    //             console.warn(e)
    //         }
    //     } catch (error) {
    //         console.warn(error)
    //     }
    //
    // }
}

import React from "react";
import './RoutesListView.css'

import {Icon, Tooltip, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,} from "@material-ui/core";

//Entities
import {GeoDriveAPI} from "../API/GeoDriveApi"

//Texts
import {Texts} from "./VariantsDetail.texts";
import {Link} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Navbar from "../../sharedCored/components/Navbar";
import "./VariantsDetails.css"


export class VariantsDetail extends React.Component {


    state: {
        isVariantsLoaded: boolean,
        variants: any,
        sortedVariants: any,
        variantsMetadata: any,
    }

    constructor(props) {
        super(props);

        this.state = {
            isVariantsLoaded: false,
            variants: null,
            sortedVariants: null,
            variantsMetadata: null,
            currentRoute: [],
        }
    }

    componentDidMount() {
        this.loadVariantData();
        this.loadVariantMetadata()
    }

    async loadVariantMetadata() {
        const variantsMetadata = await GeoDriveAPI.getVariantCount(this.props.match.params.id);
        this.setState({variantsMetadata});
    }

    async loadVariantData() {

        this.setState({
            isVariantsLoaded: true,
            variantsMetadata: null,
        });

        try {
            const res = await GeoDriveAPI.getRouteWithAllVariantsAndRides(this.props.match.params.id)

            let rawVariants = res.variants;
            let activeVariants = [];
            let inactiveVariants = [];

            for (let variant of rawVariants) {
                //Just to be sure, check if the variant has a ride !
                if (variant.rides !== null && variant.rides.length > 0) {
                    let active = false;
                    loop1: for (let track of variant.rides[0].tracks) {
                        for (let subtrack of track.subtracks) {
                            if (subtrack.production === true) {
                                active = true;
                                break loop1;
                            }
                        }
                    }
                    active === true ? activeVariants.push(variant) : inactiveVariants.push(variant);
                }
            }

            let sortedVariants = [this.sortVariantsByType(activeVariants), this.sortVariantsByType(inactiveVariants)];

            this.setState({
                isVariantsLoaded: false,
                variants: res.variants,
                sortedVariants: sortedVariants,
                currentRoute: res,

            });
        } catch (error) {
            console.log(error);
            this.setState({
                isVariantsLoaded: false
            });
        }
    }

    sortVariantsByType(variants: Array<any>) {

        let variantsTypes: Array<any> = [
            {name: "TYPE_CLASSIQUE", variants: [], color: '#DFF0D8'},
            {name: "TYPE_DEVIATION", variants: [], color: '#D9EDF7'},
            {name: "TYPE_HLP", variants: [], color: '#F2DEDE'},
            {name: "TYPE_RETOURNEMENT", variants: [], color: '#FCF8E3'},
            {name: "TYPE_AUTRE", variants: [], color: '#FFFFFF'}
        ];

        for (let variant of variants) {
            switch (variant.variantType) {
                case "TYPE_CLASSIQUE":
                    variantsTypes[0].variants.push(variant);
                    break;
                case "TYPE_DEVIATION":
                    variantsTypes[1].variants.push(variant);
                    break;
                case "TYPE_HLP":
                    variantsTypes[2].variants.push(variant);
                    break;
                case "TYPE_RETOURNEMENT":
                    variantsTypes[3].variants.push(variant);
                    break;
                case "TYPE_AUTRE":
                    variantsTypes[4].variants.push(variant);
                    break;
                default:
                    variantsTypes[4].variants.push(variant);
                    break;
            }
        }

        return variantsTypes;
    }

    prettifyType(type) {
        switch (type) {
            case "TYPE_CLASSIQUE":
                return Texts.getText("classic_variant_type");
            case "TYPE_DEVIATION":
                return Texts.getText("detour_variant_type");
            case "TYPE_HLP":
                return Texts.getText("hlp_variant_type");
            case "TYPE_RETOURNEMENT":
                return Texts.getText("turnaround_variant_type");
            case "TYPE_AUTRE":
                return Texts.getText("misc_variant_type");
            default:
                return Texts.getText("misc_variant_type");
        }
    }

    // getVariantMetadata(variant) {
    //     if (this.state.variantsMetadata !== null) {
    //         for (let metadata of this.state.variantsMetadata) {
    //             if (metadata.variant.id === variant.id) {
    //                 return ({
    //                     pois: metadata.pois.length,
    //                     dangerZones: metadata.dangerZones.length,
    //                     directions: metadata.directions.length,
    //                     reRoutingPaths: metadata.reRoutingPaths.length,
    //                     tunnelZones: metadata.tunnelZones.length,
    //                 });
    //             }
    //         }
    //         return null;
    //     } else {
    //         return null;
    //     }
    // }

    getVariantMetadata(variant) {
        if (this.state.variantsMetadata) {
            for (let metadata of this.state.variantsMetadata) {
                if (Number(metadata.variant_id) === variant.id) {
                    return ({
                        stop: metadata.stop_count,
                        dangerZones: metadata.danger_zone_count,
                        directions: metadata.direction_count,
                        reRoutingPaths: metadata.re_routing_path_count,
                        tunnelZones: metadata.tunnel_zone_count,
                    });
                }
            }
            return null;
        } else {
            return null;
        }
    }

    handleVariantClick(variant) {
        if (variant.rides !== null && variant.rides.length > 0) {
            let productionTrack = null;
            loop1: for (let track of variant.rides[0].tracks) {
                for (let subtrack of track.subtracks) {
                    if (subtrack.production === true) {
                        productionTrack = track;
                        break loop1;
                    }
                }
            }

            if (productionTrack !== null) {

                if (this.props.route) {
                    global.lastRouteParameters = {
                        route: this.props.route,
                        variant: variant
                    };
                }

                this.props.history.push("/flexnav/track/" + productionTrack.id);
            }
        }
    }

    render() {
        return (
            <div style={{width: "100%", height: "calc(100vh - 60px)"}}>
                <Navbar title={"Ensemble des variantes"}/>

                {this.state.isVariantsLoaded ?
                    <LinearProgress color="primary"/>
                    :
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        {
                            this.state.sortedVariants ?
                                <div style={{
                                    maxWidth: "80%",
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // marginTop: 50
                                }}>

                                    <div style={{
                                        // width: 800,
                                        minHeight: 80,
                                        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                                    }} className={'variant-block'}>

                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            minHeight: 50,
                                            backgroundColor: '#FFFFFF',
                                            padding: 24,
                                            borderBottom: '1px solid #CCCCCC'
                                        }}>

                                            <div style={{
                                                height: 36,
                                                minWidth: 36,
                                                borderRadius: 4,
                                                backgroundColor: this.state.currentRoute.color,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                        <span style={{
                                                            color: "#FFFFFF",
                                                            fontSize: 20,
                                                            marginLeft: 7,
                                                            marginRight: 7
                                                        }}
                                                              className={"text-bordered"}>{this.renderMaxLength(this.state.currentRoute.number)}</span>
                                            </div>

                                            <div style={{
                                                flex: 1,
                                                flexDirection: 'column',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                paddingLeft: 10
                                            }}>

                                                <span style={{
                                                    color: '#222222',
                                                    marginBottom: 2
                                                }}>{this.state.currentRoute.name}</span>
                                                <span style={{
                                                    color: '#555555',
                                                    textAlign: 'left'
                                                }}>{this.state.currentRoute.description}</span>

                                            </div>
                                        </div>

                                        <div style={{
                                            // width: 800,
                                            maxHeight: 'calc(100vh - 260px)',
                                            minHeight: 80,
                                            overflowY: 'auto'
                                        }} className={"styled-scroll"}>
                                            {this.state.sortedVariants[0].map((type, index) => (
                                                <div key={'' + index}>

                                                    <ExpansionPanel defaultExpanded={index === 0}>
                                                        <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center'
                                                            }}>

                                                                <div style={{
                                                                    height: 22,
                                                                    width: 22,
                                                                    backgroundColor: type.color,
                                                                    borderRadius: 3,
                                                                    border: '1px solid #CCCCCC',
                                                                    marginRight: 10
                                                                }}>
                                                                </div>

                                                                <span
                                                                    style={{color: '#555555'}}>{this.prettifyType(type.name)}
                                                                    <strong>{' (' + type.variants.length + ')'}</strong>
                                                                            </span>
                                                            </div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>

                                                            {
                                                                type.variants.length > 0 &&
                                                                <div>
                                                                    {
                                                                        type.variants.map((variant, index) => (
                                                                            <div key={'' + index} style={{
                                                                                width: '100%',
                                                                                minHeight: 50,
                                                                                marginBottom: 10,
                                                                                display: 'flex',
                                                                                position: 'relative',
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0,
                                                                                flexDirection: 'column'
                                                                            }}
                                                                                 className={"hoverable-line"}
                                                                                 onClick={() => {
                                                                                     this.handleVariantClick(variant)
                                                                                 }}>

                                                                                <div style={{
                                                                                    width: '100%',
                                                                                    padding: 10
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row'
                                                                                    }}>

                                                                                        <div
                                                                                            style={{width: '100%'}}>

                                                                                            <div style={{
                                                                                                width: "100%",
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                                position: 'relative',
                                                                                                paddingBottom: 10
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    width: 2,
                                                                                                    height: 'calc(100% - 12px)',
                                                                                                    position: 'absolute',
                                                                                                    top: 16,
                                                                                                    left: 7,
                                                                                                    backgroundColor: '#797979'
                                                                                                }}>
                                                                                                </div>

                                                                                                <div style={{
                                                                                                    height: 10,
                                                                                                    width: 10,
                                                                                                    borderRadius: 8,
                                                                                                    border: '2px solid #797979',
                                                                                                    backgroundColor: '#FFFFFF',
                                                                                                    marginTop: 3,
                                                                                                    marginLeft: 1
                                                                                                }}>
                                                                                                </div>
                                                                                                <p style={{
                                                                                                    width: "100%",
                                                                                                    textAlign: 'left',
                                                                                                    flex: 1,
                                                                                                    paddingLeft: 10,
                                                                                                    margin: 0,
                                                                                                    marginTop: 1,
                                                                                                    color: "#555555"
                                                                                                }}>
                                                                                                    {variant.departure}
                                                                                                </p>
                                                                                            </div>

                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row'
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    height: 10,
                                                                                                    width: 10,
                                                                                                    borderRadius: 8,
                                                                                                    border: '2px solid #797979',
                                                                                                    backgroundColor: '#797979',
                                                                                                    marginTop: 3,
                                                                                                    marginLeft: 1
                                                                                                }}>
                                                                                                </div>
                                                                                                <p style={{
                                                                                                    width: "100%",
                                                                                                    textAlign: 'left',
                                                                                                    flex: 1,
                                                                                                    paddingLeft: 10,
                                                                                                    margin: 0,
                                                                                                    marginTop: 1,
                                                                                                    color: "#555555"
                                                                                                }}>
                                                                                                    {variant.arrival}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <p style={{
                                                                                        maxWidth: 600,
                                                                                        textAlign: 'left',
                                                                                        fontWeight: 'bold',
                                                                                        margin: 0,
                                                                                        marginTop: 10,
                                                                                        color: "#0076C2"
                                                                                    }}>
                                                                                        {variant.description}
                                                                                    </p>
                                                                                </div>


                                                                                <div style={{
                                                                                    width: '100%',
                                                                                    minHeight: 40,
                                                                                    borderBottomRightRadius: 4,
                                                                                    borderBottomLeftRadius: 4,
                                                                                    borderTop: '1px solid #efefef',
                                                                                    backgroundColor: '#fafafa',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    flexWrap: 'wrap'
                                                                                }}>

                                                                                    {this.state.variantsMetadata && this.getVariantMetadata(variant) ?
                                                                                        <div style={{
                                                                                            height: "100%",
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            justifyContent: "flex-start",
                                                                                            alignItems: 'center',
                                                                                            flexWrap: "wrap",
                                                                                            padding:"4px"
                                                                                        }}>

                                                                                            {this.renderVariantInstructions(variant)}

                                                                                            <Tooltip
                                                                                                title={Texts.getText("stops_metadata_tooltip_text")}
                                                                                                enterDelay={300}>
                                                                                                <div
                                                                                                    className={"hover-badge variant-metadata-item-wrapper"}>
                                                                                                    <Icon style={{
                                                                                                        color: '#797979',
                                                                                                        marginRight: 4
                                                                                                    }}>directions_bus</Icon>
                                                                                                    <span style={{
                                                                                                        color: '#555555',
                                                                                                        fontWeight: 'bold'
                                                                                                    }}>{this.getVariantMetadata(variant).stop}</span>
                                                                                                </div>
                                                                                            </Tooltip>


                                                                                            <Tooltip
                                                                                                title={Texts.getText("directions_metadata_tooltip_text")}
                                                                                                enterDelay={300}>
                                                                                                <div
                                                                                                    className={"hover-badge variant-metadata-item-wrapper"}>
                                                                                                    <Icon style={{
                                                                                                        color: '#797979',
                                                                                                        marginRight: 4
                                                                                                    }}>directions</Icon>
                                                                                                    <span style={{
                                                                                                        color: '#555555',
                                                                                                        fontWeight: 'bold'
                                                                                                    }}>{this.getVariantMetadata(variant).directions}</span>
                                                                                                </div>
                                                                                            </Tooltip>


                                                                                            <Tooltip
                                                                                                title={Texts.getText("danger_zones_metadata_tooltip_text")}
                                                                                                enterDelay={300}>
                                                                                                <div
                                                                                                    className={"variant-metadata-item-wrapper hover-badge"}>
                                                                                                    <Icon style={{
                                                                                                        color: '#797979',
                                                                                                        marginRight: 4
                                                                                                    }}>warning</Icon>
                                                                                                    <span style={{
                                                                                                        color: '#555555',
                                                                                                        fontWeight: 'bold'
                                                                                                    }}>{this.getVariantMetadata(variant).dangerZones}</span>
                                                                                                </div>
                                                                                            </Tooltip>


                                                                                            <Tooltip
                                                                                                title={Texts.getText("tunnel_zones_tooltip_text")}
                                                                                                enterDelay={300}>
                                                                                                <div
                                                                                                    className={"variant-metadata-item-wrapper hover-badge"}>
                                                                                                    <Icon style={{
                                                                                                        color: '#797979',
                                                                                                        marginRight: 4
                                                                                                    }}>subway</Icon>
                                                                                                    <span style={{
                                                                                                        color: '#555555',
                                                                                                        fontWeight: 'bold'
                                                                                                    }}>{this.getVariantMetadata(variant).tunnelZones}</span>
                                                                                                </div>
                                                                                            </Tooltip>


                                                                                            <Tooltip
                                                                                                title={Texts.getText("re_routing_metadata_tooltip_text")}
                                                                                                enterDelay={300}>
                                                                                                <div
                                                                                                    className={"variant-metadata-item-wrapper hover-badge"}>
                                                                                                    <Icon style={{
                                                                                                        color: '#797979',
                                                                                                        marginRight: 4
                                                                                                    }}>timeline</Icon>
                                                                                                    <span style={{
                                                                                                        color: '#555555',
                                                                                                        fontWeight: 'bold'
                                                                                                    }}>{this.getVariantMetadata(variant).reRoutingPaths}</span>
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            {this.renderVariantInstructions(variant)}
                                                                                            <div style={{
                                                                                                color: '#CCCCCC',
                                                                                                paddingLeft: 10
                                                                                            }}>{Texts.getText("metadata_loading_text")}</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                : null
                        }


                    </div>
                }
            </div>
        )
    }

    renderMaxLength(number) {
        if (number.length < 4) {
            return number
        } else {
            if (typeof number === "number") {
                const convert = String(number)
                return convert.substring(0, 4)
            } else if (typeof number === "string") {
                return number.substring(0, 4)
            }
        }
    }

    renderVariantInstructions(variant) {

        if (variant.rides[0].tracks[0].trackPreviewInfos.length > 0) {
            return (
                <Tooltip title={"Voir la consigne"} enterDelay={200}>
                    <div style={{

                    }} className={"variant-metadata-instructions"}>
                        <Icon style={{color: '#32CD32', marginRight: 4}}>check_box</Icon>
                        <span style={{color: '#555555',}}>Consigne</span>
                    </div>
                </Tooltip>
            )
        } else {
            return (
                <Link to={`/instructions/${variant.rides[0].tracks[0].id}`} target="_blank"
                      style={{textDecoration: 'none'}}>
                    <Tooltip title={"Aucune consigne créée. Cliquer pour en créer !"} enterDelay={200}>
                        <div  className={"variant-metadata-instructions"} >
                            <Icon style={{color: '#797979', marginRight: 4}}>check_box_outline_blank</Icon>
                            <span style={{color: '#555555', fontStyle: 'italic'}}>Aucune consigne</span>
                        </div>
                    </Tooltip>
                </Link>
            )
        }

    }
}

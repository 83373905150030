import React, {Component} from 'react';
import {Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink} from "@react-pdf/renderer";

import stop_pin from "../../../assets/maps_assets/icon_bus_stop_v2.png";
import tbt_pin from "../../../assets/maps_assets/tbt_pin - Copy.png";
import WarningIcon from "../../../assets/maps_assets/warning_500_orange.png";
import {Direction} from "../entities/Direction";
import {TrackPreviewInfoAPI} from "./API/TrackPreviewInfoAPI";

class PdfInstructions extends Component {

    render() {
        // Create styles
        const styles = StyleSheet.create({
            page: {
                width: '100%',
                height: '100%',
                padding: 40,
            },
            polaroid: {
                width: '100%',
                backgroundColor: 'white',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20
            },
            image: {
                maxHeight: 500,
                width: 'auto',
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                objectFit: 'cover'
            },
            commentImage: {
                textAlign: 'left',
                borderLeft: '1 solid #1E2129',
                borderRight: '1 solid #1E2129',
                borderBottom: '1 solid #1E2129',
                padding: 20,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                backgroundColor: '#FAFAFA',
                fontStyle: 'italic'
            },
            pageNumber: {
                position: 'absolute',
                fontSize: 12,
                bottom: 30,
                left: 0,
                right: 0,
                textAlign: 'center',
                color: 'grey',
            },
        });
        console.log('preview info ', this.props.previewInfo);
        console.log('route', this.props.route);
        console.log('variant', this.props.variant);
        return (
            <Document>
                {this.props.previewInfo && this.props.route && this.props.variant &&

                <Page size="A4" style={styles.page}>

                    {/*Title*/}
                    <View style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', minHeight:50, padding: 20, border: "1 solid #CCCCCC"}}>

                        <View style={{height:36, minWidth:36, border: '1 solid rgb(34, 34, 34)', borderRadius:4, backgroundColor: `${this.props.route.color}`, display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Text style={{color: "#FFFFFF", fontSize: 20, marginLeft:7, marginRight:7}}>{this.props.route.number}</Text>
                        </View>

                        <View style={{flex:1, flexDirection:'column', display: 'flex', justifyContent:'center', alignItems:'flex-start', paddingLeft:10}}>
                            <Text style={{color:'#222222', marginBottom:2}}>Consignes de la {this.props.route.name}</Text>
                            <Text style={{color:'#555555', textAlign:'left'}}>{this.props.variant.departure} => {this.props.variant.arrival}</Text>
                        </View>
                    </View>

                    {/*Description*/}
                    <Text style={{color:'#3F98F4', textAlign: 'left', padding: 20}}>
                        {this.props.variant.description}
                    </Text>
                    {/*StartText*/}
                    <Text style={{color: 'rgba(0, 0, 0, 0.87)', textAlign: 'left', textDecoration: 'underline', padding: 20}}>
                        Début des consignes
                    </Text>
                    <Text style={{color: 'black', textAlign: 'left'}}>{this.props.previewInfo.startText}</Text>

                    {/*Image*/}
                    {this.props.previewInfo.trackPreviewInfoFiles.filter(file => file.distanceFromOrigin === 0).map(image => {
                        return (
                            <View key={`${image.id} - pdfImage`} style={styles.polaroid} >
                                <Image
                                    style={styles.image}
                                    source={image.downloadRoute}
                                />
                                {image.textValue.length > 1 && <Text style={styles.commentImage}>{image.textValue}</Text>}
                            </View>
                        )
                    })}

                    {/*Steps*/}
                    <Text break style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, letterSpacing: 0.03, textAlign: 'center', textDecoration: 'underline', paddingBottom: 20}}>
                        Début de ligne
                    </Text>

                    <View style={{height: '100%', width: '100%', backgroundColor:'#FFFFFF', paddingLeft:50}}>
                        {this.props.previewInfo.trackPreviewInfoSteps.map((instruction, index) => {
                            return (
                                <View wrap={false} key={index + "Instructions"} style={{display: 'flex', justifyContent: 'flex-start', textAlign: 'left', margin: '0 auto', width:"100%"}} >
                                    {this.renderInstruction(instruction)}
                                </View>
                            )
                        })
                        }
                    </View>

                    <Text style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, letterSpacing: 0.03, textAlign: 'center', textDecoration: 'underline', paddingTop: 20, paddingBottom: 20}}>
                        Fin de ligne
                    </Text>

                    {/*EndText*/}
                    <Text break style={{color: 'rgba(0, 0, 0, 0.87)', textAlign: 'left', textDecoration: 'underline', padding: 20}}>
                        Fin des consignes
                    </Text>
                    <Text style={{color: 'black', textAlign: 'left'}}>{this.props.previewInfo.endText}</Text>

                    {/*Image*/}
                    {this.props.previewInfo.trackPreviewInfoFiles.filter(file => file.distanceFromOrigin > 0).map(image => {
                        return (
                            <View key={`${image.id} - pdfImage`} style={styles.polaroid} >
                                <Image
                                    style={styles.image}
                                    source={image.downloadRoute}
                                />
                                {image.textValue.length > 1 && <Text style={styles.commentImage}>{image.textValue}</Text>}
                            </View>
                        )
                    })}
                    <Text fixed style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )}  />
                </Page>
                }
            </Document>

        );
    }

    renderInstruction(instruction) {

        if (instruction.dataType === 'stop') {

            return (
                <View style={{display:"flex", flexDirection:"row", width: '90%', justifyContent:"flex-start", alignItems:'center', height: '60px'}}>
                    <View style={{width: 6, backgroundColor: this.renderBorder(instruction), height:"100%"}} />
                    <Image src={stop_pin} alt={"stop_pin"} style={{height: 30, width: 30, position: 'relative', right: 18, top:2}}/>
                    <Text style={{color: '#222222', fontSize: 18, textAlign:"left", margin:0, paddingRight:10, paddingTop: 10}}>{instruction.textValue}</Text>

                    <Text style={{fontSize: 13, color: '#777', fontWeight: 300, paddingTop: 12, opacity: instruction.distanceToNextStop === 0 ? 0 : 1}}>
                        Prochain arrêt:
                    </Text>
                    <Text style={{fontSize: 13, color: '#777', paddingTop: 12, opacity: instruction.distanceToNextStop === 0 ? 0 : 1, fontWeight: 700}}>
                        &nbsp; {TrackPreviewInfoAPI.getPrettyDistance(instruction.distanceToNextStop)}
                    </Text>
                </View>
            )
        }

        else if (instruction.dataType === 'dangerZoneStart') {
            return (
                <View style={{display:"flex", flexDirection:"row", width: '90%', justifyContent:"flex-start", alignItems:'center', height: 40}}>
                    <View style={{width: 6, backgroundColor: '#F49900', height:"100%"}} />
                    <View style={{display: 'flex', flexDirection:"row", justifyContent:"flex-start", alignItems: 'center', paddingLeft: 30, minHeight:40, paddingTop:5,
                        paddingBottom:5, flex:1}}>
                        <Image src={WarningIcon} alt={"Warning"} style={{height: 15, width: 15}}
                        />
                        <Text style={{color: '#F49900', fontSize: 14, flex:1, textAlign:"left", paddingLeft:5, paddingRight:10}}>
                            {instruction.textValue.charAt(0).toUpperCase() + instruction.textValue.slice(1)}
                        </Text>
                    </View>
                </View>
            )
        }

        else if (instruction.dataType === 'dangerZoneEnd') {
            return (
                <View style={{display:"flex", flexDirection:"row", width: '90%', height: 20}}>
                    <View style={{width: 6, backgroundColor: this.renderBorder(instruction), height:"100%"}} />
                </View>
            )
        }

        else if (instruction.dataType === 'direction') {
            return (
                <View style={{display:"flex", flexDirection:"column", height: '60px'}}>

                    <View style={{display:"flex", flexDirection:"row", width: '90%', justifyContent:"flex-start", alignItems:'center'}}>
                        <View style={{width: 6, backgroundColor: this.renderBorder(instruction), height:instruction.distanceToNextDirection === 0 ? 60 : 40}} />
                        <Image src={tbt_pin} alt={"tbt_pin"} style={{height:14, position: 'relative', right: 10, paddingRight: 10}}/>
                        <Image src={Direction.getTBTIndicationByPosition(instruction.pictoCode).src} alt={instruction.textValue} style={{height: 30}}/>
                        <Text style={{lineHeight: '20px', fontSize: 14, color: 'rgba(0, 0, 0, 0.87)'}}>{instruction.textValue}</Text>
                        <View style={{flex: 1}} />
                    </View>

                    <View style={{display: "flex", flexDirection: "row", width: '100%', justifyContent: "flex-start", alignItems: 'center'}}>
                        <View style={{width: 6,  backgroundColor: this.renderBorder(instruction), height: 20}}/>

                        <Text style={{display: 'flex', alignItems: 'center', paddingLeft: 30, paddingRight: 5, opacity: instruction.distanceToNextDirection === 0 ? 0 : 1,
                            fontSize: 11, color: '#777', fontWeight: 300}}>
                            {TrackPreviewInfoAPI.getPrettyDistance(instruction.distanceToNextDirection)}
                        </Text>
                        <View style={{height: '1px', backgroundColor: '#C4C4C4', width: '180px', marginLeft: 5}}/>
                        <View style={{flex: 1}} />
                    </View>

                </View>
            )
        }

    }

    renderBorder (instruction) {
        if (instruction.isInDangerZone) {
            return (
                '#F49900'
            )
        } else {
            return (
                '#3F98F4'
            )
        }
    }
}

export default PdfInstructions;

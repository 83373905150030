import React, {Component} from 'react';
import stop_pin from "../../../../assets/maps_assets/icon_bus_stop_v2.png";
import Icon from "@material-ui/core/Icon";
import tbt_pin from "../../../../assets/maps_assets/tbt_pin - Copy.png"
import {TrackPreviewInfoAPI} from "../../instruction/API/TrackPreviewInfoAPI";
import {Direction} from "../../entities/Direction";

class RenderInstructions extends Component {
    render() {
        return (
            <div>
                {this.renderInstruction(this.props.instruction)}
            </div>
        );
    }

    renderInstruction(instruction) {

        if (instruction.dataType === 'stop') {

            return (
                <div style={{display:"flex", flexDirection:"column", height: '60px'}}>
                    <div style={{display:"flex", flexDirection:"row", width: '100%', justifyContent:"flex-start", alignItems:'center', height: '100%'}}>
                        <div style={{width: 6, backgroundColor: this.renderBorder(instruction), height:"100%"}} />
                        <img src={stop_pin} alt={"stop_pin"} style={{height: 30, width: 30, position: 'relative', right: 18, top:2,zIndex:20}}/>
                        <p style={{color: '#222222', fontSize: 16, fontWeight: 'bold', flex:1, textAlign:"left", margin:0, paddingRight:10, paddingTop: 10}}>
                            {instruction.textValue}
                            <span style={{fontSize: 13, color: '#777', fontWeight: 300, paddingTop: 10, paddingLeft: 5, opacity: instruction.distanceToNextStop === 0 ? 0 : 1}}>
                        Prochain arrêt: <span style={{ fontSize: 13, fontWeight: 'bold',}}> {TrackPreviewInfoAPI.getPrettyDistance(instruction.distanceToNextStop)}</span>
                    </span>
                        </p>
                    </div>
                </div>
            )
        }

        else if (instruction.dataType === 'dangerZoneStart') {
            return (
                <div style={{display:"flex", flexDirection:"row", width: '100%', justifyContent:"flex-start", alignItems:'center', height: 40}}>
                    <div style={{width: 6, backgroundColor: `#F49900`, height: '100%'}} />
                    <div style={{ display: 'flex', flexDirection:"row", justifyContent:"flex-start", alignItems: 'center',  paddingLeft: 30, minHeight:40, paddingTop:5, paddingBottom:5,
                        flex:1}}>
                        <Icon style={{color: '#F49900', fontSize: 16, textAlign: 'center'}}>warning</Icon>
                        <p style={{color: '#F49900', fontSize: '14px', fontWeight: 400, flex:1, margin:0, textAlign:"left", paddingLeft:5, paddingRight:10}}>
                            {instruction.textValue.charAt(0).toUpperCase() + instruction.textValue.slice(1)}
                        </p>
                    </div>
                </div>
            )
        }

        else if (instruction.dataType === 'dangerZoneEnd') {
            return (
                <div style={{display:"flex", flexDirection:"row", width: '100%', height: '20px'}}>
                    <div style={{width: 6, backgroundColor: this.renderBorder(instruction), height:"100%"}} />
                </div>
            )
        }

        else if (instruction.dataType === 'direction') {
            return (
                <div style={{display:"flex", flexDirection:"column", height: '60px'}}>

                    <div style={{display:"flex", flexDirection:"row", width: '100%', justifyContent:"flex-start", alignItems:'center'}}>
                        <div style={{minWidth: 6, backgroundColor: this.renderBorder(instruction), height:instruction.distanceToNextDirection === 0 ? 60 : 40}} />
                        <img src={tbt_pin} alt={"tbt_pin"} style={{height:14, position: 'relative', right: 10, zIndex:20}}/>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8, paddingRight: 5}}>
                            <img src={Direction.getTBTIndicationByPosition(instruction.pictoCode).src} alt={instruction.textValue} style={{height: 30}}/>
                            <p style={{lineHeight: '20px',fontWeight: 400, fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)', margin: 0}}>{instruction.textValue}</p>
                        </div>
                    </div>

                    {instruction.distanceToNextDirection !== 0 &&
                    < div style={{display: "flex", flexDirection: "row", width: '100%', justifyContent: "flex-start", alignItems: 'center'}}>
                        <div style={{width: 6,  backgroundColor: this.renderBorder(instruction), height: 20}}/>

                        <div style={{display: 'flex', alignItems: 'center', paddingLeft: 30, paddingRight: 5, opacity: instruction.distanceToNextDirection === 0 ? 0 : 1}}>
                            <span style={{fontSize: '11px', color: '#777', fontWeight: 300}}>{TrackPreviewInfoAPI.getPrettyDistance(instruction.distanceToNextDirection)}</span>
                            <div style={{height: '1px', backgroundColor: '#C4C4C4', width: '180px', marginLeft: 5}}/>
                        </div>
                    </div>
                    }
                </div>
            )
        }
    }

    renderBorder (instruction) {
        if (instruction.isInDangerZone) {
            return (
                '#F49900'
            )
        } else {
            return (
                '#3F98F4'
            )
        }
    }
}

export default RenderInstructions;
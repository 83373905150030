import {Subtrack} from "./Subtrack";
import {LatLng} from "../../../geo-utilities/entities/LatLng";

let direction_arrive = require('../../../assets/tbt_directions/Dark/direction_arrive.png');

let direction_roundabout = require('../../../assets/tbt_directions/Dark/direction_roundabout.png');
let direction_roundabout_right = require('../../../assets/tbt_directions/Dark/direction_roundabout_right.png');
let direction_roundabout_slight_right = require('../../../assets/tbt_directions/Dark/direction_roundabout_slight_right.png');
let direction_roundabout_sharp_right = require('../../../assets/tbt_directions/Dark/direction_roundabout_sharp_right.png');
let direction_roundabout_left = require('../../../assets/tbt_directions/Dark/direction_roundabout_left.png');
let direction_roundabout_slight_left = require('../../../assets/tbt_directions/Dark/direction_roundabout_slight_left.png');
let direction_roundabout_sharp_left = require('../../../assets/tbt_directions/Dark/direction_roundabout_sharp_left.png');
let direction_roundabout_straight = require('../../../assets/tbt_directions/Dark/direction_roundabout_straight.png');


let direction_turn_left = require('../../../assets/tbt_directions/Dark/direction_turn_left.png');
let direction_turn_slight_left = require('../../../assets/tbt_directions/Dark/direction_turn_slight_left.png');
let direction_turn_sharp_left = require('../../../assets/tbt_directions/Dark/direction_turn_sharp_left.png');
let direction_turn_right = require('../../../assets/tbt_directions/Dark/direction_turn_right.png');
let direction_turn_slight_right = require('../../../assets/tbt_directions/Dark/direction_turn_slight_right.png');
let direction_turn_sharp_right = require('../../../assets/tbt_directions/Dark/direction_turn_sharp_right.png');
let direction_uturn = require('../../../assets/tbt_directions/Dark/direction_uturn.png');
let direction_turn_straight = require('../../../assets/tbt_directions/Dark/direction_turn_straight.png');

export class Direction {

  id:number;
  latitude:number;
  longitude:number;
  subtrack:Subtrack;
  textToSpeach:string;
  textToDisplay:string;
  position:number;
  draggable:boolean;
  isOpen:boolean;

constructor(id:number, latitude:number, longitude:number, textToSpeach:string, textToDisplay:string, position:number) {
    this.id = id;
    this.latitude = latitude;
    this.longitude = longitude;
    this.textToDisplay = textToDisplay;
    this.textToSpeach = textToSpeach;
    this.position = position;
  }

  static create() {
    return new Direction(null,null,null,"","",null);
  }


    toLatLng() : LatLng {
        return new LatLng(this.latitude, this.longitude);
    }

  clone() {
    return new Direction(this.id, this.latitude, this.longitude, this.textToSpeach, this.textToDisplay, this.position);
  }

  prepare() {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      tts: this.textToSpeach,
      ttd: this.textToDisplay,
      position: this.position,
      minDistance: 200,
      maxDistance:0
    };
  }

  static mapDirections(raw:any) : Array<Direction> {
    let result:Array<Direction> = [];
    for(let o of raw) {
      result.push(Direction.mapDirection(o));
    }
    return result;
  }


  static mapDirection(o) : Direction {
    return new Direction(o.id, o.latitude, o.longitude, o.tts, o.ttd, o.position);
  }


  mapAsRawNavigationInstruction() {
      return {
          tts: this.textToSpeach,
          ttd: this.textToDisplay,
          lat: this.latitude,
          lon: this.longitude,
          pictoCode: this.position
      }
  }


  getImageSRC(){
    let allIndications=[];

    allIndications = allIndications.concat(Direction.tbt_indications.roads);
    allIndications = allIndications.concat(Direction.tbt_indications.roundabouts);
    allIndications = allIndications.concat(Direction.tbt_indications.terminus);

    return allIndications[this.position].src

    // if (this.position <= 7){
    //   for (var indication of Direction.tbt_indications.roads){
    //     if (indication.position == this.position){
    //       return indication.src;
    //     }
    //   }
    // }
    // else{
    //   for (var indication of Direction.tbt_indications.roundabouts){
    //     if (indication.position == this.position){
    //       return indication.src;
    //     }
    //   }
    // }
  }

  isValid() {
    return this.latitude && this.longitude && this.textToSpeach && this.textToDisplay && this.position !== null;
  }

  getTBTMarkerAssetPath() {
    return "assets/map/icontbtbluemini.png";
  }

  static getTBTIndicationByPosition(position) {
      for(let indic of Direction.tbt_indications.roads) {
          if(indic.position === position) return indic;
      }
      for(let indic of Direction.tbt_indications.roundabouts) {
          if(indic.position === position) return indic;
      }
      for(let indic of Direction.tbt_indications.terminus) {
          if(indic.position === position) return indic;
      }

  }

  static tbt_indications={
    roads:[
      {
        position: 0,
        description: "Continuer tout droit",
        tts:"Continuez tout droit",
        ttd:"Continuez tout-droit",
        src: direction_turn_straight
      },
      {
        position: 1,
        description: "Tourner légèrement à droite",
        tts:"Tournez à droite",
        ttd:"Tournez à droite",
        src: direction_turn_slight_right
      },
      {
        position: 2,
        description: "Tourner à droite",
        tts:"Tournez à droite",
        ttd:"Tournez à droite",
        src: direction_turn_right
      },
      {
        position: 3,
        description: "Tourner fortement à droite",
        tts:"Tournez à droite",
        ttd:"Tournez à droite",
        src: direction_turn_sharp_right
      },
      {
        position: 4,
        description: "Faire demi-tour",
        tts:"Faites demi-tour",
        ttd:"Faites demi-tour",
        src: direction_uturn
      },
      {
        position: 5,
        description: "Tourner fortement à gauche",
        tts:"Tournez à gauche",
        ttd:"Tournez à gauche",
        src: direction_turn_sharp_left
      },
      {
        position: 6,
        description: "Tourner à gauche",
        tts:"Tournez à gauche",
        ttd:"Tournez à gauche",
        src: direction_turn_left
      },
      {
        position: 7,
        description: "Tourner légèrement à gauche",
        tts:"Tournez à gauche",
        ttd:"Tournez à gauche",
        src: direction_turn_slight_left
      },
    ],
    roundabouts:[
      {
        position: 8,
        description: "Au rond-point, continuer tout droit",
        tts:"Au rond-point, continuez tout droit",
        ttd:"Au rond-point, continuez tout-droit",
        src: direction_roundabout_straight
      },
      {
        position: 9,
        description: "Au rond-point, tourner légèrement à droite",
        tts:"Au rond-point, tournez à droite",
        ttd:"Au rond-point, tournez à droite",
        src: direction_roundabout_slight_right
      },
      {
        position: 10,
        description: "Au rond-point, tourner à droite",
        tts:"Au rond-point, tournez à droite",
        ttd:"Au rond-point, tournez à droite",
        src: direction_roundabout_right
      },
      {
        position: 11,
        description: "Au rond-point, tourner fortement à droite",
        tts:"Au rond-point, tournez à droite",
        ttd:"Au rond-point, tournez à droite",
        src: direction_roundabout_sharp_right
      },
      {
        position: 12,
        description: "Au rond-point, faire demi-tour",
        tts:"Au rond-point, faites demi-tour",
        ttd:"Faites demi-tour",
        src: direction_uturn
      },
      {
        position: 13,
        description: "Au rond-point, tourner fortement à gauche",
        tts:"Au rond-point, tournez à gauche",
        ttd:"Au rond-point, tournez à gauche",
        src: direction_roundabout_sharp_left
      },
      {
        position: 14,
        description: "Au rond-point, tourner à gauche",
        tts:"Au rond-point, tournez à gauche",
        ttd:"Au rond-point, tournez à gauche",
        src: direction_roundabout_left
      },
      {
        position: 15,
        description: "Au rond-point, tourner légèrement à gauche",
        tts:"Au rond-point, tournez à gauche",
        ttd:"Au rond-point, tournez à gauche",
        src: direction_roundabout_slight_left
      },
        {
            position: 16,
            needsExitNumber: true,
            exitNumber: null,
            description: "Au rond point:",
            tts: "Au rond-point",
            ttd: "Au rond-point",
            src: direction_roundabout
        },
    ],
      terminus:[
        {
            position:17,
            description: "Arrivée terminus",
            tts:"Allez jusqu'au terminus",
            ttd:"Allez jusqu'au terminus",
            src: direction_arrive
        }
    ]
  };

    static roundabout_exit_options=[
        {
            description: "Continuer tout droit",
            ttd:"Au rond-point, continuez tout droit",
            tts:"Au rond-point, continuez tout droit",
        },
        {
            description: "Prendre la 1ère sortie",
            ttd:"Au rond-point, prenez la 1ère sortie",
            tts:"Au rond-point, prenez la première sortie",
        },
        {
            description: "Prendre la 2ème sortie",
            ttd:"Au rond-point, prenez la 2ème sortie",
            tts:"Au rond-point, prenez la deuxième sortie",
        },
        {
            description: "Prendre la 3ème sortie",
            ttd:"Au rond-point, prenez la 3ème sortie",
            tts:"Au rond-point, prenez la troisième sortie",
        },
        {
            description: "Prendre la 4ème sortie",
            ttd:"Au rond-point, prenez la 4ème sortie",
            tts:"Au rond-point, prenez la quatrième sortie",
        },
        {
            description: "Prendre la 5ème sortie",
            ttd:"Au rond-point, prenez la 5ème sortie",
            tts:"Au rond-point, prenez la cinquième sortie",
        },
        {
            description: "Prendre la dernière sortie",
            ttd:"Au rond-point, prenez la dernière sortie",
            tts:"Au rond-point, prenez la dernière sortie",
        },
        {
            description: "Faire demi-tour",
            ttd:"Au rond-point, faites demi-tour",
            tts:"Au rond-point, faites demi-tour",
        },
    ]


    static getPositionFromGoogleMapsManoeuver(manoeuver) {
        switch (manoeuver) {
            case "straight":
                return 0;
            case "turn-slight-right":
                return 1;
            case "turn-right":
                return 2;
            case "turn-sharp-right":
                return 3;
            case "uturn-right":
                return 4;
            case "uturn-left":
                return 4;
            case "turn-sharp-left":
                return 5;
            case "turn-left":
                return 6;
            case "turn-slight-left":
                return 7;
            case "roundabout_straight":
                return 16;
            case "roundabout_slight_right":
                return 16;
            case "roundabout-right":
                return 16;
            case "roundabout_sharp_right":
                return 16;
            case "roundabout_sharp_left":
                return 16;
            case "roundabout-left":
                return 16;
            case "roundabout_slight_left":
                return 16;
            default:
                return 0;
        }
    }
}

import {Variant} from "./Variant";
export class Route {
    id:number;
    name:string;
    color:string;
    number:string;
    description:string;
    variants:Array<Variant>;


    selected = false;        //For display purposes
    hovered:boolean=false;

    constructor(name:string, number:string, description:string = "", id:number = null,color:string) {
        this.id = id;
        this.name = name;
        this.color = (color === "" || color === null) ? '#3F98F4' : color;
        this.number = number;
        this.description = description;
    }

    /**
     * Mapping the routes
     * @param rawArray
     * @returns {Array}
     */
    static mapRoutes(rawArray:any) : Array<Route> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Route.mapRoute(o));
            }
        } catch(err) {
            console.log(err);
            return result;
        }
        return result;
    }

    /**
     * Mapping the routes
     * @param rawArray
     * @returns {Array}
     */
    static deepMapRoutes(rawArray:any) : Array<Route> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Route.mapRoute(o));
            }
        } catch(err) {
            console.log(err);
            return result;
        }
        return result;
    }

    getContrastYIQ() {
      var r = parseInt(this.color.substr(0,2),16);
      var g = parseInt(this.color.substr(2,2),16);
      var b = parseInt(this.color.substr(4,2),16);
      var yiq = ((r*299)+(g*587)+(b*114))/1000;
      return (yiq >= 140) ? 'black' : 'white';
    }

    static create() :Route {
        return new Route("", "", "", null, "");
    }

    clone():Route{
        return new Route(this.name, this.number, this.description, this.id, this.color);
    }

    static mapRoute(rawObject:any) {
        let route:Route = new Route(rawObject.name, rawObject.number, rawObject.description, rawObject.id, rawObject.color);
        if(route.color && route.color.length  === 6){
            route.color = '#' + route.color;
        }
        return route;
    }

    static deepMapRoute(rawObject:any) {
        let result =  new Route(rawObject.name, rawObject.number, rawObject.description, rawObject.id, rawObject.color);
        result.variants = Variant.deepMapVariants(rawObject.variants)
    }

    static clone(route:Route) : Route {
        return new Route(
            route.name,
            route.number,
            route.description,
            route.id,
            route. color);
    }

    prepare(){
        let result =  {
            name: this.name,
            number: this.number,
            color: this.color.length === 7 ? this.color.slice(1) : this.color.length === 0 ? "3F98F4" : this.color,
            description: this.description
        };
        return result;
    }

    static prepareGeneric(obj){
        let result =  {
            name: obj.name,
            number: obj.number,
            color: obj.color.length === 7 ? obj.color.slice(1) : obj.color.length === 0 ? "3F98F4" : obj.color,
            description: obj.description
        };
        return result;
    }

    static checkIntegrity(route:Route):boolean{
        return (route.name && route.name.length >=2 && route.number && route.name.length >=2);
    }

    isValid() {
        try {
            return this.number && this.number.length  > 0 && this.name && this.name.length > 0;
        } catch(error) {
            return false;
        }
    }
}

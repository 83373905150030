import React, {Component} from 'react';
import './App.css';
import Login from "./App/authentification/login";
import CredentialsStorage from "./App/sharedCored/entities/CredentialsStorage";
import {BrowserRouter as Router, Link, NavLink, Redirect, Route, Switch} from 'react-router-dom';
import RoutesListView from "./App/geoDrive/routesListView/RoutesListView";
import {VariantsDetail} from "./App/geoDrive/routesListView/VariantsDetail";
import DrivingRuleView from "./App/geoDrive/drivingRuleView/drivingRuleView";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticationDone: false
        };
        CredentialsStorage.mountInstant()
    }

    componentDidMount(): void {
    }

    componentDidAuthenticate() {
        this.setState({authenticationDone: true})
        if (global.proxyCompany) {
            document.title = 'Hubup | ' + global.proxyCompany.tag;
        }
    }

    componentNotAuthenticate() {
        this.setState({authenticationDone: false})
    }


    render() {
        return (
            <Router>

                {/*GLOBAL APPS*/}
                <Login onAuthenticationDone={() => {
                    this.componentDidAuthenticate()
                }} onAuthentificationNotDone={() => this.componentNotAuthenticate()}/>
                {
                    this.state.authenticationDone &&
                    <div style={{maxWidth: "100vw"}}>
                        <Switch>

                            <Route path="/" exact={true} history={this.props.history} match={this.props.match}
                                   render={(props) => (
                                       <RoutesListView {...props}/>
                                   )}/>
                            <Route path="/flexnav/routes/:id" history={this.props.history} match={this.props.match}
                                   render={(props) => (
                                       <VariantsDetail {...props}/>
                                   )}/>
                            <Route path="/flexnav/track/:trackId" history={this.props.history} match={this.props.match}
                                   render={(props) => (
                                       <DrivingRuleView {...props}/>
                                   )}/>
                        </Switch>
                        {/*<Redirect from="/" to="/home" />*/}
                    </div>
                }
            </Router>
        )
    }


}

export default App;

import React, {Component} from 'react';
import stop_pin from "../../../assets/maps_assets/icon_bus_stop_v2.png";
import Icon from "@material-ui/core/Icon";
import {Direction} from "../entities/Direction";
import tbt_pin from "../../../assets/maps_assets/tbt_pin - Copy.png";
import "./instructions.css"
import {Button, Tooltip} from "@material-ui/core";

import ReactPDF, {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import CircularProgress from "@material-ui/core/CircularProgress";
import PdfInstructions from "./pdfInstructions";
import RenderInstructions from "../drivingRuleView/Components/RenderInstructions";

class MapInstructions extends Component {

    render() {

        return (
            <div id='instructions-menu' className={'instructions-menu'} >


                {this.props.route && this.props.data &&
                <div style={{position:"relative", height:"100%"}}>

                    {/*Header*/}
                    {/*<div style={{width: '100%', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingtop: 5}}*/}
                    {/*     className={"noselect"}>*/}

                    {/*   */}
                    {/*</div>*/}
                    {/*<PDFViewer width={400} height={800}>*/}
                    {/*    <PdfInstructions previewInfo={this.state.rawData} route={this.props.route && this.props.route} variant={this.props.variant && this.props.variant}/>*/}
                    {/*</PDFViewer>*/}

                    <div style={{backgroundColor: '#FAFAFA', borderLeft: "1px solid #CCCCCC", borderTop: "1px solid #CCCCCC",  borderTopLeftRadius: 4,borderBottomLeftRadius: 4}}
                         className={"scrolled-bar-instructions"}>
                        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div style={{width: 366, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 49}}>
                                <p style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, letterSpacing: '0.03em', paddingRight: 10}}>
                                    <span style={{boxShadow: `inset 0 -8px 0 0 #${this.props.route.color}`}}>
                                   CONSIGNES DE PARCOURS
                                    </span>
                                </p>
                                <div style={{
                                    height: 30, minWidth: 30, borderRadius: 4, border: '1px solid rgb(34, 34, 34)', backgroundColor: `#${this.props.route.color}`,
                                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <span style={{color: "#FFFFFF", fontSize: 16, margin: '0 auto'}} className={"text-bordered"}>{this.props.route.number}</span>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", alignItems: 'center', height: 34, width: 34, borderRadius: 17, cursor: "pointer"}}
                                 className={"flat-button"}
                                 onClick={() => this.props.closeSideMenu()}
                            >
                                <Icon style={{color: '#555555', fontSize: 22}}>clear</Icon>
                            </div>
                        </div>

                        {/*Consignes*/}
                        {this.props.data.startText &&
                        <p style={{textAlign: 'left', padding: 20, margin: 0}}>{this.props.data.startText}</p>
                        }
                        {/*Liste Images*/}
                        <div style={{
                            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 10, paddingTop: 20
                        }}>
                            {this.props.data.trackPreviewInfoFiles.filter(file => file.distanceFromOrigin === 0).map((file, index) => {
                                return (
                                    <div style={{padding: 5, position: 'relative'}} key={`${index}) stepsInstructions / ${file.filename}`} className={"instructions-images"}>
                                        <Tooltip title={file.textValue && file.textValue.length > 1 ? `${file.textValue.slice(0, 50)}...` : ''} placement={"bottom"}>
                                            <a href={file.downloadRoute} target={"_blank"}>
                                                <img src={'data:image/png;base64,' + file.thumbnail} style={{width: 85, height: 85, borderRadius: '4px', border: '1px solid #ddd'}}
                                                     alt={file.filename}/>
                                            </a>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </div>

                        {/*Steps*/}
                        <div style={{width: '100%', height: 50, paddingLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                             className={"noselect"}>
                            <div style={{width: 400, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Icon style={{color: '#555555', fontSize: 22}}>play_arrow</Icon>
                                <p style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, letterSpacing: '0.02857em', textDecoration: 'underline'}}>
                                    Début de ligne
                                </p>
                            </div>
                        </div>
                        <div style={{paddingLeft: 15}}>
                            {this.props.data.trackPreviewInfoSteps.map((instruction, index) => {
                                return (
                                    <div key={index + "Instructions"} style={{display: 'flex', justifyContent: 'flex-start', textAlign: 'left', margin: '0 auto', width: "100%"}}>
                                        {/*{this.renderInstruction(instruction)}*/}
                                        <RenderInstructions
                                            instruction={instruction}
                                        />
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div style={{width: '100%', height: 50, paddingLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                             className={"noselect"}>
                            <div style={{width: 400, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Icon style={{color: '#555555', fontSize: 22}}>stop</Icon>
                                <p style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500, letterSpacing: '0.02857em', textDecoration: 'underline'}}>
                                    Fin de ligne
                                </p>
                            </div>
                        </div>
                        {/*Last instruction text*/}
                        {this.props.data.endText &&
                        <p style={{textAlign: 'left', padding: 20, margin: 0}}>{this.props.data.endText}</p>
                        }
                        {/*List Images*/}
                        <div style={{
                            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 10, paddingTop: 20
                        }}>
                            {this.props.data.trackPreviewInfoFiles.filter(file => file.distanceFromOrigin > 0).map((file, index) => {
                                return (
                                    <div style={{padding: 5, position: 'relative'}} key={`${index}) stepsInstructions / ${file.filename}`} className={"instructions-images"}>
                                        <Tooltip title={`${file.textValue.slice(0, 50)}...`} placement={"bottom"}>
                                            <a href={file.downloadRoute} target={"_blank"}>
                                                <img src={'data:image/png;base64,' + file.thumbnail} style={{width: 85, height: 85, borderRadius: '4px', border: '1px solid #ddd'}}
                                                     alt={file.filename}/>
                                            </a>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
                    // Any instructions created yet
                }
            </div>

        );
    }

}

export default MapInstructions;

import {LatLngBoundsLiteral} from "../interfaces/LatLngBoundsLitteral";
import {LatLngLiteral} from "../interfaces/LatLngLitteral";
import {LatLng} from "./LatLng";


export class LatLngBounds implements LatLngBoundsLiteral {


  minLat:number;  //south
  maxLat:number;  //north
  minLng:number;  //west
  maxLng:number;  //east

  northEast: LatLngLiteral;
  southWest: LatLngLiteral;

  east: number;
  north: number;
  south: number;
  west : number;

  constructor(c1: LatLngLiteral, c2: LatLngLiteral) {
      this.maxLat = c1.lat > c2.lat ? c1.lat : c2.lat;
      this.maxLng = c1.lng > c2.lng ? c1.lng : c2.lng;
      this.minLat = c1.lat < c2.lat ? c1.lat : c2.lat;
      this.minLng = c1.lng < c2.lng ? c1.lng : c2.lng;

      this.south = this.minLat;
      this.north = this.maxLat;
      this.west = this.minLng;
      this.east = this.maxLng;

      this.southWest = new LatLng(this.minLat, this.minLng);
      this.northEast = new LatLng(this.maxLat, this.maxLng);
  }

  toGoogleLatLngBouldsLiteral() {
      return {
          north: this.north,
          south: this.south,
          east: this.east,
          west: this.west
      }
  }


}

import React, {Component} from 'react';

import './login.css'
import {LoginApi} from "./api/LoginApi";
import CredentialsStorage from "../sharedCored/entities/CredentialsStorage";
import LoginFormCompany from "./Components/LoginFormCompany";
import LoginFormUser from "./Components/LoginFormUser";


class Login extends Component {


    constructor(props) {

        super(props);
        this.state = {

            displayLoginPopup: false,
            proxyCompany:'',
            proxyCompanyTag: "",

            user:"",
            username:"",
            password:"",
        };
    }
    authenticationCheckSubscription;
    possibleInvalidJWTSubscription;

    componentDidMount(): void {
        this.checkForStoredCompany()
        this.getCredential();
        this.initMessageListener();
        this.possibleInvalidJWTSubscription = global.emitter.addListener("wipeJWT", async ()=>{

            const user = await CredentialsStorage.getUser();
            if (user && user.roles[0] === "ROLE_SHARED_TERMINAL") {
                    this.authenticateSHARED_TERMINAL_USER()
            } else {
                this.logout()
            }
        });
        this.authenticationCheckSubscription = global.emitter.addListener("logout",()=>{this.logout()});
    }

    componentWillUnmount(){
        if(this.authenticationCheckSubscription){
            this.authenticationCheckSubscription.remove()
        }
        if(this.possibleInvalidJWTSubscription){
            this.possibleInvalidJWTSubscription.remove()
        }
    }
    async checkForStoredCompany(){
        if(this.state.proxyCompany){
            // console.log('Proxy company found in global variable');
            this.checkForStoredUser();
        }
        else{
            // console.log('Checking for Proxy Company in localStorage');
            let pc = await CredentialsStorage.getProxyCompany();
            if(pc){
                //RESET LOCAL PC;
                global.proxyCompany = pc
                this.setState({
                    proxyCompany:global.proxyCompany
                },()=>{this.checkForStoredUser()});
            }
            else{
                // console.log('No Proxy Company found in localStorage, displaying login popup');
                //GLOBAL WIPE
                global.proxyCompany = null;
                global.user = null;
                global.jwt = null;
                await CredentialsStorage.removeAll

                this.showLoginPopup();
                this.props.onAuthentificationNotDone()
            }
        }
    }

    async checkForStoredUser(){
        if(this.state.user){
            // console.log('User found in global variable');
        }
        else {
            let usr = await CredentialsStorage.getUser()
            let jwt = await CredentialsStorage.getJWT()

            if(usr && jwt){
                //RESET LOCAL PC;
                global.user = usr
                global.jwt = jwt;
                this.handleDisplayLoginPopup()
            }
            else{
                global.proxyCompany = null;
                global.user = null;
                global.jwt = null;
                await CredentialsStorage.removeAll

                this.showLoginPopup();
            }
        }
    }

    logout () {
        global.user = null;
        global.jwt = null;

        CredentialsStorage.removeJWT()
        CredentialsStorage.removeUser()

        this.showLoginPopup();
        this.props.onAuthentificationNotDone()
    }


    async getCredential() {
        const proxyCompany = await CredentialsStorage.getProxyCompany();
        const credentials = await CredentialsStorage.getCredentials();
        global.proxyCompany = proxyCompany;
        this.setState({
            proxyCompany,
            username: credentials[0],
            password: credentials[1]
        })
    }

    async authenticateSHARED_TERMINAL_USER(){
        try {
            global.user = null;
            global.jwt = null;
            const credentials = await CredentialsStorage.getCredentials();
            const user = await LoginApi.login(credentials[0], credentials[1], this.state.proxyCompany)
            global.user = user;


        } catch (error) {
            console.warn(error);
            this.logout()
        }
        this.props.onAuthentificationNotDone()
        this.props.onAuthenticationDone()
    }



    render() {

        if(this.state.displayLoginPopup === false){
            return null;
        }

        return (
            <div>
                <div style={{position:'fixed', height:'100%', width:'100%', zIndex: 1}} className={"blurred-bg"}>
                    <div style={{flexDirection:'column', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {
                            this.state.proxyCompany ?
                                <LoginFormUser username={this.state.username} password={this.state.password} proxyCompany={this.state.proxyCompany} handleChangeUsername={(value) => this.handleChangeUsername(value)}
                                               handleChangePassword={(value) => this.handleChangePassword(value)} connected={() => this.handleDisplayLoginPopup()}
                                               handleChangeCompany={() => this.handleChangeCompany()}  />
                                : <LoginFormCompany handleChangeTag={(value) => this.handleChangeTag(value)}
                                                    proxyCompanyTag={this.state.proxyCompanyTag} updateProxyCompany={(element) => this.updateProxyCompany(element)}
                                />
                        }
                </div>
                </div>

            </div>
        );
    }

    handleDisplayLoginPopup() {
        this.setState({
            displayLoginPopup: false
        },()=> {
            this.props.onAuthenticationDone()
        })

    }

    handleChangeTag(value){
        this.setState({
            proxyCompanyTag:value.toUpperCase(),
        })
    }

    updateProxyCompany (proxyCompany) {
        this.setState({proxyCompany: proxyCompany})
    }



    handleChangeUsername(value) {
        this.setState({
            username:value
        })
    }

    handleChangePassword(value){
        this.setState({
            password:value,
        })
    }

    handleChangeCompany () {
        this.setState({
            proxyCompany: "",
            proxyCompanyTag: "",
            user: "",
            username:"",
            password:"",
        })
    }

    showLoginPopup(){
        this.setState({
            displayLoginPopup:true
        })
    }

    initMessageListener(){
        window.addEventListener("message", (event)=>{
            try{
                if(event.data && event.data.length > 0){
                    let data = JSON.parse(event.data);
                    if(data && data.action === "LOGIN"){
                        this.tryLoginFromPayload(data)
                    }
                }
            }
            catch(e){console.log(e)}
        }, false);
    }

    async tryLoginFromPayload(data){
        this.removeUserAndProxyCompany();
        this.authenticateProxyCompanyAndUser(data)
    }

    removeUserAndProxyCompany(){
        global.user = null;
        global.jwt = null;
        global.proxyCompany = null;

        CredentialsStorage.removeAll();

        this.showLoginPopup();
        this.props.onAuthentificationNotDone()
    }

    async authenticateProxyCompanyAndUser(data) {

        try {
            const proxyCompany = await LoginApi.getCompanyByTag(data.company);
            await CredentialsStorage.storeProxyCompany(JSON.stringify(proxyCompany));
            global.proxyCompany = proxyCompany;

            const user = await LoginApi.login(data.username, data.password, proxyCompany)
            global.user = user;
            await CredentialsStorage.storeCredentials(data.username, data.password)
            await CredentialsStorage.storeUser(JSON.stringify(user))

            this.onLoginFromPayloadDone();
        } catch (error) {
            console.log(error);
            this.removeUserAndProxyCompany();
        }

    }

    onLoginFromPayloadDone(){
        this.setState({
            displayLoginPopup: false
        },()=> {
            this.props.onAuthenticationDone()
        })
    }
}

export default Login;

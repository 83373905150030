import {Subtrack} from "./Subtrack";
import {LatLng} from "../../../geo-utilities/entities/LatLng";

export class DangerZone {

  id:number;
  latitude:number;
  longitude:number;
  subtrack:Subtrack;
  textToSpeech:string;
  textToDisplay:string;
  applyDistance:number;
  draggable:boolean;
  isOpen:boolean;
    path:Array<any>;

  constructor(id:number, latitude:number, longitude:number, textToSpeech:string, textToDisplay:string, applyDistance:number) {
    this.id = id;
    this.latitude = latitude;
    this.longitude = longitude;
    this.textToDisplay = textToDisplay;
    this.textToSpeech = textToSpeech;
    this.applyDistance = applyDistance;
    this.path = null;
  }

  static create() {
    return new DangerZone(null,null,null,"","",null);
  }

  prepare() {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      ttd: this.textToDisplay,
      tts: this.textToSpeech,
      applyDistance: this.applyDistance
    };
  }


    toLatLng() : LatLng {
        return new LatLng(this.latitude, this.longitude);
    }



  clone() {
    return new DangerZone(this.id, this.latitude, this.longitude, this.textToSpeech, this.textToDisplay, this.applyDistance);
  }

  static mapDangerZones(raw:any) : Array<DangerZone> {
    let result:Array<DangerZone> = [];
    for(let o of raw) {
      result.push(DangerZone.mapDangerZone(o));
    }
    return result;
  }


  static mapDangerZone(o) : DangerZone {
    return new DangerZone(o.id, o.latitude, o.longitude, o.tts, o.ttd, o.applyDistance);
  }


  isValid() {
    return this.latitude && this.longitude && this.textToSpeech && this.textToDisplay && this.applyDistance
  }

  isValidApplyDistance(applyDistance:any){
    return (/^\d+$/g).test(applyDistance);
  }

  getTBTMarkerAssetPath() {
    return "assets/map/icondangerzoneorangemini.png";
  }





}

// @flow

import {ProxyParameter} from "./ProxyParameter";

export class ProxyCompany {

    id:number;
    name:string;
    shortName:string;
    serverUrl:string;
    resetPasswordUrl:string;
    tag:string;
    parameters:Array<ProxyParameter>;
    defaultLat:number;
    defaultLng:number;
    toBeChecked: boolean;

    constructor(id: number, name: string, shortName: string, serverUrl:string, resetPasswordUrl:string, tag: string, parameters: Array<ProxyParameter>) {
        this.id = id;
        this.name = name;
        this.shortName = shortName;
        this.serverUrl = serverUrl;
        this.resetPasswordUrl = resetPasswordUrl;
        this.tag = tag;
        this.parameters = parameters;
    }

    /**
     *
     */
    serialize() : any {

        return {
            name: this.name,
            serverUrl: this.serverUrl,
            resetPasswordUrl: this.resetPasswordUrl,
            tag: this.tag,
            shortName: this.shortName,
            // parameters: ProxyParameter.serializeArray(this.parameters)
            defaultLat: this.defaultLat,
            defaultLng: this.defaultLng,
            toBeCheck: this.toBeChecked
        };
    }


    static mapProxyCompany(rawObject:any) : ProxyCompany {
        try {
            let parameters: Array<ProxyParameter> = [];
            for(let parameter of rawObject.parameters) {
                parameters.push(ProxyParameter.mapParameter(parameter));
            }

            let pc = new ProxyCompany(rawObject.id, rawObject.name, rawObject.short_name, rawObject.server_url, rawObject.reset_password_url, rawObject.tag, parameters);

            if(rawObject.default_lat && rawObject.default_lng){
                pc.defaultLat = rawObject.default_lat;
                pc.defaultLng = rawObject.default_lng;
            }
            return pc;

        } catch(error) {
            console.log(error);
        }
    }

    static mapParameter(rawObject: any): ProxyParameter|null {
        try {
            return new ProxyParameter(rawObject.id, rawObject.type, rawObject.content);
        } catch (error) {
            console.log(error);
            return null;
        }
    }

}
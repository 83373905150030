import {RESTManager} from "../../sharedCored/api/RESTManager";
import {FOSRouter} from "../../FOS/fos.router";

export class FlexnavLogApi {


    static postFlexnavLog(payload) : Promise<any>  {
        return new Promise((resolve, reject)=> {
            RESTManager.post("/shared/usage/info",payload)
                .then((response:any) => {
                    resolve()
                })
                .catch((err)=>{
                    reject(err);
                })
        });
    }


}

import React, {Component} from 'react';

import Hubup_Logo from "../../../assets/hubup_logo_skewed_blue_white_bg.svg";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {LoginApi} from "../api/LoginApi";
import CredentialsStorage from "../../sharedCored/entities/CredentialsStorage";

class LoginFormUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            showCredentialsError: false
        };
    }

    componentDidMount() {
        this.resetCompanyState()
        this.getUrlParamsAndAuthenticate()
    }

    resetCompanyState = () => {
        if (!global.proxyCompany) {
            this.props.handleChangeCompany()
        }
    }

    getUrlParamsAndAuthenticate = () => {
        let params = new URLSearchParams(document.location.search)
        let username = params.get("username")
        let password = params.get("password")
        if (username && password) {
            if (global.proxyCompany) {
                this.authenticateUser(username, password)
            }

        }
    }

    async authenticateUser(un = null, pass = null) {
        let userName = un || this.props.username
        let password = pass || this.props.password
        this.props.handleChangePassword(pass)
        this.props.handleChangeUsername(un)
        this.setState({isLoaded: true});
        try {
            const user = await LoginApi.login(userName, password, this.props.proxyCompany)
            global.user = user;
            await CredentialsStorage.storeCredentials(userName, password)
            await CredentialsStorage.storeUser(JSON.stringify(user))
            this.setState({isLoaded: false})
            this.props.connected()
        } catch (error) {
            console.warn(error);
            this.setState({
                isLoaded: false,
                showCredentialsError: true
            });
        }
    }

    async forgetProxyCompany() {

        global.proxyCompany = null;
        global.user = null;
        global.jwt = null;

        await CredentialsStorage.removeAll();
        this.props.handleChangeCompany();
        this.setState({
            showCredentialsError: '',
        });
    }

    render() {
        let isCompanyLoggedWithUrlParameters = new URLSearchParams(document.location.search)?.get("company")
        return (
            <div>
                <div className={"card-login"}>

                    <div className={"flex-overflow-special"}
                         style={{
                             width: 'auto',
                             display: 'flex',
                             flexDirection: 'row',
                             justifyContent: 'center',
                             alignItems: 'center',
                             marginBottom: 10
                         }}>
                        <img src={Hubup_Logo} alt="image_line" style={{height: 65, width: 65}}/>
                        <span className={"login-title"} style={{maxWidth: 200}}>
                            Connexion aux applications
                        </span>
                    </div>

                    <TextField
                        label="Identifiant ou adresse e-mail"
                        style={{width: '100%', minHeight: 48}}
                        helperText={this.state.showCredentialsError ? "Identifiants invalides" : null}
                        value={this.props.username === "null" ? " " : this.props.username}
                        onChange={(event) => {
                            this.props.handleChangeUsername(event.target.value)
                        }}
                        margin="normal"
                        disabled={this.state.isLoaded}
                        required={true}
                        error={this.state.showCredentialsError}
                        variant={"outlined"}
                    />

                    <TextField
                        label="Mot de passe"
                        style={{width: '100%', minHeight: 48}}
                        helperText={this.state.showCredentialsError ? "Identifiants invalides" : null}
                        type="password"
                        value={this.props.password === "null" ? "" : this.props.password}
                        onChange={(event) => {
                            this.props.handleChangePassword(event.target.value)
                        }}
                        margin="normal"
                        disabled={this.state.isLoaded}
                        required={true}
                        error={this.state.showCredentialsError}
                        variant={"outlined"}
                    />

                    <div style={{
                        height: 80,
                        paddingTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {
                            this.state.isLoaded ?
                                <CircularProgress style={{color: '#3f98f4'}}/>
                                :
                                <Button variant={"contained"}
                                        style={{backgroundColor: '#3f98f4'}}
                                        onClick={() => {
                                            this.authenticateUser()
                                        }}
                                >
                                    <span style={{color: '#FFFFFF', marginTop: 2, marginRight: 4}}>SUIVANT</span>
                                    <Icon style={{color: '#FFFFFF', fontSize: 18}}>arrow_forward</Icon>
                                </Button>
                        }

                    </div>
                </div>
                {
                    // remove the change company link if we arrive from Inlink with a Belliard account
                    (this.props.proxyCompany && !isCompanyLoggedWithUrlParameters) ?
                        <div style={{height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span style={{
                                        color: '#BBBBBB',
                                        fontSize: 13
                                    }}>Entreprise {this.props.proxyCompany.tag}
                                        <span style={{color: '#3f98f4', marginLeft: 10}}
                                              onClick={() => {
                                                  this.forgetProxyCompany()
                                              }}
                                              className={"text-button"}>CHANGER</span>
                                    </span>
                        </div>
                        : null
                }
            </div>
        );
    }

}

export default LoginFormUser;
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Hubup_Logo from "../../../assets/hubup_logo_skewed_blue_white_bg.svg";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {LoginApi} from "../api/LoginApi";
import CredentialsStorage from "../../sharedCored/entities/CredentialsStorage";

class LoginFormCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            showCompanyTagError: false
        };
    }

    componentDidMount() {
     this.getUrlParamsAndAuthenticate()
    }

    render() {
        return (
            <div>
                <div className={"card-login"}>
                    <div className={"flex-overflow-special"}
                         style={{
                             width: 'auto',
                             display: 'flex',
                             flexDirection: 'row',
                             justifyContent: 'center',
                             alignItems: 'center',
                             marginBottom: 10
                         }}>
                        <img src={Hubup_Logo} alt="image_line" style={{height: 65, width: 65}}/>

                        <span className={"login-title"}>
                       Connexion
                    </span>
                    </div>

                    <span style={{
                        color: '#555555',
                        textAlign: 'center',
                        fontSize: 14,
                        maxWidth: 300,
                        marginTop: 30,
                        marginBottom: 20
                    }}>
                       Entrez votre identifiant d'entreprise pour accéder à l'application
                    </span>

                    <TextField
                        label="Identifiant d'entreprise"
                        style={{width: '100%', minHeight: 48}}
                        helperText={this.state.showCompanyTagError ? "Identifiant invalide" : ""}
                        value={this.props.proxyCompanyTag}
                        onChange={(event) => {
                            this.props.handleChangeTag(event.target.value)
                        }}
                        margin="normal"
                        disabled={this.state.isLoaded}
                        required={true}
                        error={this.state.showCompanyTagError}
                        variant={"outlined"}
                    />

                    <div style={{
                        height: 80,
                        paddingTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative'
                    }}>
                        {
                            this.state.isLoaded ?
                                <CircularProgress style={{color: '#3f98f4', position: "absolute", top: 0}}/>
                                :
                                <Button variant={"contained"} style={{backgroundColor: '#3f98f4'}}
                                        onClick={() => {
                                            this.authenticateProxyCompany()
                                        }}
                                >
                                    <span style={{color: '#FFFFFF', marginTop: 2, marginRight: 4}}>SUIVANT</span>
                                    <Icon style={{color: '#FFFFFF', fontSize: 18}}>arrow_forward</Icon>
                                </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }

    getUrlParamsAndAuthenticate = () => {
        let params = new URLSearchParams(document.location.search)
        let company = params.get("company")
        if(company){
            this.props.handleChangeTag(company)
            this.authenticateProxyCompany(company)
        }
    }

    async authenticateProxyCompany(ct = null) {
        let companyTag = ct || this.props.proxyCompanyTag

        this.setState({isLoaded: true});
        try {
            const proxyCompany = await LoginApi.getCompanyByTag(companyTag);
            proxyCompany.loggedWithUrlParams = !!ct;
            global.proxyCompany = proxyCompany;
            await CredentialsStorage.storeProxyCompany(JSON.stringify(proxyCompany));
            this.props.updateProxyCompany(proxyCompany);
            this.setState({isLoaded: false,});
        } catch (error) {
            console.log(error);
            this.setState({
                isLoaded: false,
                showCompanyTagError: true
            });
        }

    }
}

export default withRouter(LoginFormCompany);
// @flow

import {ProxyCompany} from "./ProxyCompany";

export class ProxyParameter {

    id: number;
    type: string;
    content: string;
    proxyCompany: ProxyCompany;

    constructor(id: number, type: string, content: string) {
        this.id = id;
        this.type = type;
        this.content = content;
    }

    static mapParameters(rawArray: any): Array<ProxyParameter> {
        let result: Array<ProxyParameter> = [];
        for (let o of rawArray) {
            let res = ProxyParameter.mapParameter(o);
            if(res !== null) result.push();
        }

        return result;
    }


    /**
     * Serializes an array of proxy company parameters.
     * @param parameters
     */
    static serializeArray(parameters:Array<ProxyParameter>) : Array<any> {
        let result = [];
        for(let p of parameters) {
            result.push(p.serialize());
        }
        return result;
    }

    /**
     * Serialize the current parameter
     */
    serialize() : any {
        return {
            id: this.id,
            type: this.type,
            content: this.content,
        };
    }

    prepare() : any {
        return {
            proxyCompany: this.proxyCompany.id,
            type: this.type,
            content: this.content,
        };
    }


    /**
     *
     * @param o
     * @returns {ProxyParameter}
     */
    static unSerialize(o:any) : ProxyParameter {
        return new ProxyParameter(o.id, o.type, o.content);
    }


    static unSerializeArray(raw:Array<any>) : Array<ProxyParameter> {
        const result:Array<ProxyParameter> = [];
        if(raw !== null && raw.length > 0) {
            for(let o of raw) {
                let r:ProxyParameter|null = ProxyParameter.unSerialize(o);
                if(r !== null) {
                    result.push(r);
                }
            }
        }
        return result;
    }


    static mapParameter(rawObject: any): ProxyParameter|null {
        try {
            return new ProxyParameter(rawObject.id, rawObject.type, rawObject.content);
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}

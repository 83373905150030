import {Route} from "./Route";
import {Ride} from "./Ride.js";
import {POI} from "./POI";


export class Variant {

    route:Route;
    id:number;
    departure:string;
    arrival:string;
    specificity:string;
    description:string;
    variantType:string;
    rides:Array<Ride>;
    favorite:boolean = false;

    hovered:boolean=false;
    // typeClassique:string = "TYPE_CLASSIQUE";
    // typeDeviation:string = "TYPE_DEVIATION";
    // typeHLP:string = "TYPE_HLP";
    // typeRetournement:string = "TYPE_RETOURNEMENT";
    // typeDivers:string = "TYPE_AUTRE";

    constructor(id: number, departure: string, arrival: string, specificity: string, description: string, variantType:string) {
        this.id = id;
        this.departure = departure;
        this.arrival = arrival;
        this.specificity = specificity;
        this.description = description;
        this.variantType = variantType;
        this.rides = [];
    }

    prepare(route:Route) {
      if(route ===  null || route.id ===  null || route.id <= 0) {
        console.error("Variant.prepare() : provided route had no correct ID", route);
      }
      return {
        departure: this.departure,
        arrival : this.arrival,
        specificity : this.specificity,
        description : this.description,
        variantType: this.variantType,
        route : route.id
      }
    }


    static prepareGeneric(obj, route){
        let result =  {
            departure: obj.departure,
            arrival: obj.arrival,
            specificity: obj.specificity,
            description: obj.description,
            variantType: obj.variantType,
            route: route.id,
        };
        return result;
    }

    getSingleRide() : Ride {
        if(this.rides && this.rides.length) {
            return this.rides[0];
        }
        return null;
    }



    /**
     * Returns forward ride (if exists)
     * @returns {any}
     */
    getForwardRide() : Ride {
        let result = null;
        if(this.rides ===  null) return null;
        for(let ride of this.rides) {
            if(!ride.backward) return ride;
        }
        return null;
    }


    /**
     * Returns backward ride (if exists)
     * @returns {any}
     */
    getBackwardRide() : Ride {
        let result = null;
        if(this.rides ===  null) return null;
        for(let ride of this.rides) {
            if(ride.backward) return ride;
        }
        return null;
    }

    static create() :Variant {
        return new Variant(null, "", "", "", "", "");
    }

    clone() :Variant {
        return new Variant(
            this.id,
            this.departure,
            this.arrival,
            this.specificity,
            this.description,
            this.variantType
        );
    }

    getPrettyVariantType(){
        let result:string="";
        switch(this.variantType){
            case "TYPE_CLASSIQUE":
                result = "Trajet classique";
                break;
            case "TYPE_DEVIATION":
                result = "Déviation";
                break;
            case "TYPE_HLP":
                result = "Haut-le-pied";
                break;
            case "TYPE_RETOURNEMENT":
                result = "Retournement";
                break;
            case "TYPE_AUTRE":
                result = "Trajet divers";
                break;
            default:
                result = "Trajet divers";
        }
        return result;
    }

    /**
     * Mapping the variants
     * @param rawArray
     * @returns {Array}
     */
    static mapVariants(rawArray:any) : Array<Variant> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Variant.mapVariant(o));
            }
        } catch(err) {
            console.log(err);
            return result;
        }
        return result;
    }
    /**
     * Mapping the variants
     * @param rawArray
     * @returns {Array}
     */
    static deepMapVariants(rawArray:any) : Array<Variant> {
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Variant.mapVariant(o));
            }
        } catch(err) {
            console.log(err);
            return result;
        }
        return result;
    }

    static mapVariant(rawObject:any) : Variant {
        let result =  new Variant(rawObject.id, rawObject.departure, rawObject.arrival, rawObject.specificity, rawObject.description, rawObject.variantType);
        return result;
    }

    static deepMapVariant(rawObject:any) : Variant {
        let result = new Variant(rawObject.id, rawObject.departure, rawObject.arrival, rawObject.specificity, rawObject.description, rawObject.variantType);
        result.rides = [];
        for(let rawRide in rawObject.rides) {
            result.rides.push(Ride.mapRide(rawRide));
        }
        return result;
    }
    static checkIntegrity(variant:Variant):boolean{
        return (variant.departure && variant.departure.length >= 2 && variant.arrival && variant.arrival.length >= 2);
    }

    hasSubtrack() : boolean {
        if(this.rides && this.rides[0] &&
            this.rides[0].tracks && this.rides[0].tracks[0] &&
            this.rides[0].tracks[0].subtracks && this.rides[0].tracks[0].subtracks[0] &&
            this.rides[0].tracks[0].subtracks[0].points && this.rides[0].tracks[0].subtracks[0].points.length
        ) {
            return true;
        }
        return false;
    }

    hasPOI(poi:POI) {
        if(this.rides && this.rides[0] && this.rides[0].pois) {
            for(let cpoi of this.rides[0].pois) {
                if(cpoi.id ===  poi.id) return true;
            }
        }
        return false;
    }

    isValid():boolean{
        return (
            this.departure !== null &&
            this.departure.length !== 0 &&
            this.arrival.length !== null &&
            this.arrival.length !== 0
        )
    }
}

import {ConvertiblePosition} from "../entities/ConvertiblePosition";
import {LatLng} from "../entities/LatLng";

export class Encoder {

    static decodePolyline(encoded:string, precision:number = 6) : Array<ConvertiblePosition> {
        const points = [];
        let index = 0;
        const len = encoded.length;
        let lat = 0, lng = 0;
        while (index < len) {
            let b, shift = 0, result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);


            const dlat = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
            lat += dlat;
            shift = 0;
            result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlng = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
            lng += dlng;

            if(precision === 6) {
                points.push(new ConvertiblePosition(lat / 1E6, lng / 1E6));
            } else {
                points.push(new ConvertiblePosition(lat / 1E5, lng / 1E5));
            }
        }
        return points;
    }


    static encodePolyLine(coordinates:Array<LatLng>, precision:number = 5) {
        coordinates = coordinates.map((e:LatLng) => {return [e.lat, e.lng]});
        if (!coordinates.length) { return ''; }

        let factor = Math.pow(10, precision),
            output = Encoder.encode(coordinates[0][0], 0, factor) + Encoder.encode(coordinates[0][1], 0, factor);

        for (let i = 1; i < coordinates.length; i++) {
            const a = coordinates[i], b = coordinates[i - 1];
            output += Encoder.encode(a[0], b[0], factor);
            output += Encoder.encode(a[1], b[1], factor);
        }

        return output;
    };



    static py2_round(value) {
        // Google's polyline algorithm uses the same rounding strategy as Python 2, which is different from JS for negative values
        return Math.floor(Math.abs(value) + 0.5) * (value >= 0 ? 1 : -1);
    }

    static encode(current, previous, factor) {
        current = Encoder.py2_round(current * factor);
        previous =Encoder.py2_round(previous * factor);
        let coordinate = current - previous;
        coordinate <<= 1;
        if (current - previous < 0) {
            coordinate = ~coordinate;
        }
        let output = '';
        while (coordinate >= 0x20) {
            output += String.fromCharCode((0x20 | (coordinate & 0x1f)) + 63);
            coordinate >>= 5;
        }
        output += String.fromCharCode(coordinate + 63);
        return output;
    }


}
import {Subtrack} from "./Subtrack";


export class TunnelZone {

  id:number;
  latitude:number;
  longitude:number;
  subtrack:Subtrack;
  textToDisplay:string;
  applyDistance:number;
  path:Array<any> =[];

constructor(id:number, latitude:number, longitude:number, textToDisplay:string, applyDistance:number) {
    this.id = id;
    this.latitude = latitude;
    this.longitude = longitude;
    this.textToDisplay = textToDisplay;
    this.applyDistance = applyDistance;
    this.path = null;
  }

  static create() {
    return new TunnelZone(null,null,null,"",null);
  }

  prepare() {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      ttd: this.textToDisplay,
      applyDistance: this.applyDistance
    };
  }


  clone() {
    return new TunnelZone(this.id, this.latitude, this.longitude, this.textToDisplay, this.applyDistance);
  }

  static mapArray(raw:any) : Array<TunnelZone> {
    let result:Array<TunnelZone> = [];
    for(let o of raw) {
      result.push(TunnelZone.map(o));
    }
    return result;
  }


  static map(o) : TunnelZone {
    return new TunnelZone(o.id, o.latitude, o.longitude, o.ttd, o.applyDistance);
  }





}

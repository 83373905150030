import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import logoHubup from "../../../assets/hubup_logo_skewed_blue_white_bg.svg";
import {Button, Icon, IconButton, Menu, MenuItem, Tooltip} from "@material-ui/core";
import "./Navbar.css"

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: false
        }
    }

    goBack(){
        window.history.back();
    }

    handleClickAnchorEl(event){
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseAnchorEl(){
        this.setState({ anchorEl: false });
    };

    logout(){
        this.setState({ anchorEl: false }, global.emitter.emit('logout')
        );
    };


    render() {
        // let isCompanyLoggedWithUrlParameters = global.proxyCompany.loggedWithUrlParams
        return (
            <div>
                {/*Navbar*/}
                <div className={"top-app-bar"}>

                    <Link to={"/"} style={{textDecoration: 'none', display:"flex", alignItems:"center"}}>
                        <img className={'top-app-bar-logo'} src={logoHubup} alt="Logo Hubup"/>
                    </Link>

                    <Tooltip title={"Page précédente"} placement={"bottom"}>
                        <IconButton
                            onClick={()=>{this.goBack()}}
                            style={{marginLeft: 20}}>
                            <Icon style={{color:'#555555'}}>arrow_back</Icon>
                        </IconButton>
                    </Tooltip>
                    <span className={'navbar-title'}>
                             {this.props.title}
                        </span>

                    <div style={{flex: 1}}/>
                    {
                        // !isCompanyLoggedWithUrlParameters &&
                        <>
                            <Button variant={"contained"} size={"small"} className={'top-app-bar-desktop-button'}
                                    onClick={(event) => this.handleClickAnchorEl(event)}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <Icon style={{color: '#555555', marginRight: 8, fontSize: 16}}>account_circle</Icon>
                                    {global.user && <span
                                        style={{color: '#555555'}}>{global.user.firstName} {global.user.lastName}</span>}
                                </div>
                            </Button>
                            <IconButton onClick={(event) => this.handleClickAnchorEl(event)} className={'top-app-bar-mobile-button'} >
                                <Icon style={{color: '#555555', marginRight: 8, fontSize: 24}}>account_circle</Icon>
                            </IconButton>
                        </>

                    }



                    <Menu
                        id="creation-menu"
                        anchorEl={this.state.anchorEl}
                        open={this.state.anchorEl}
                        onClose={()=>{this.handleCloseAnchorEl()}}
                    >
                        <MenuItem onClick={()=>{this.logout()}}>
                            Déconnexion
                        </MenuItem>
                    </Menu>
                </div>
                {/*Fin Navbar*/}
            </div>
        );
    }
}

export default withRouter(Navbar);
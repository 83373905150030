import moment from "moment";

export class Tools {

    static empty(data)
    {
        if(data === undefined) return true;
        if(data === null) return true;
        if(typeof(data) === 'number' || typeof(data) === 'boolean')
        {
            if(data === 0 || data === false) {
                return true;        //Not exactly what it should be, but better to fit with PHP's implementation.
            }
            return false;
        }
        if(typeof(data) === 'undefined' || data === null)
        {
            return true;
        }
        if(typeof(data.length) !== 'undefined')
        {
            return data.length === 0;
        }
        let count = 0;
        for(let i in data)
        {
            if(data.hasOwnProperty(i))
            {
                count ++;
            }
        }
        return count === 0;
    }

    static updateCanNavigate(){
        global.canNavigate = false;
        setTimeout(()=>{
            global.canNavigate = true;
        },1000)
    }

    static getContrastYIQ(hexcolor){
        if(hexcolor.length === 7){
            hexcolor = hexcolor.substring(1);
        }

        let r = parseInt(hexcolor.substr(0,2),16);
        let g = parseInt(hexcolor.substr(2,2),16);
        let b = parseInt(hexcolor.substr(4,2),16);
        let yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 140) ? '#333333' : 'white';
    }

    static getDistanceFromLatLngs(p1, p2) : number {
        let lat1 = p1.lat;
        let lng1 = p1.lng;
        let lat2 = p2.lat;
        let lng2 = p2.lng;

        let pi80 = Math.PI / 180;
        lat1 *= pi80;
        lng1 *= pi80;
        lat2 *= pi80;
        lng2 *= pi80;

        /**
         * originally used value : 6372.797
         * Since we're operating around the 48" lat value, I selected a more accurate value here :
         * https://rechneronline.de/earth-radius/
         *
         * @type {number}
         */
        let r = 6366.371;
        let dlat = lat2 - lat1;
        let dlng = lng2 - lng1;
        let a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let km = r * c;

        return (km * 1000)
    }

    static decodeLatLngsByEric(encoded: string): Array<any> {

        const points = [];
        let index = 0;
        const len = encoded.length;
        let lat = 0, lng = 0;
        while (index < len) {
            let b, shift = 0, result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);


            lat += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
            shift = 0;
            result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            lng += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));

            points.push({latitude: lat / 1E5, longitude: lng / 1E5});
        }
        return points;
    }



    static isElementInListById(element, list){
        for(let listElement of list){
            if(listElement.id === element.id){
                return true;
            }
        }
        return false;
    }

    static getPolylineBounds(track_points){
        return {
            minLat : Math.min.apply(null, track_points.map(function(d) { return d.latitude })),
            maxLat : Math.max.apply(null, track_points.map(function(d) { return d.latitude })),
            minLng : Math.min.apply(null, track_points.map(function(d) { return d.longitude })),
            maxLng : Math.max.apply(null, track_points.map(function(d) { return d.longitude }))
        }
    }

    static getPolylineLength(track_points){
        let result = 0;
        for(let i = 0; i< track_points.length - 1;i++){
            result = result + Tools.getDistanceFromLatLngs(track_points[i],track_points[i+1])
        }
        return result;
    }


    static parseISODate(s: string) {
        return moment(s).toDate();
    }


    static getDefaultLatLng(){
        if(global.proxyCompany && global.proxyCompany.defaultLat && global.proxyCompany.defaultLng){
            return(
                {lat:parseFloat(global.proxyCompany.defaultLat),
                    lng:parseFloat(global.proxyCompany.defaultLng)}
            )
        }
        else{
            return(
                {lat:48.834982,lng:2.358239}
            )
        }

    }

    static getPrettyDistance(dist){
        let unit = dist < 1000 ? "m" : "km";
        if(dist < 1000){
            let meters = Math.round(dist/10)*10;
            return meters + ' ' + unit;
        }
        else{
            let kilometers = Math.round(dist/100)/10;
            return kilometers + ' ' + unit
        }
    }

    static getPrettyDuration(dur){

        let hours = Math.floor(dur /60);
        let minutes = Math.floor(dur - hours * 60);

        let result = minutes + " min";

        if(hours > 0){
            result = hours + " h " + result;
        }
        return result;
    }

    static withoutMillisIsoString(date) {
        return date.toISOString().split('.')[0]+"Z";
    }

    static clearDuplicatesById(arr) {
        let ids = [];
        let res = [];
        for(let el of arr) {
            if(ids.indexOf(el.id) === -1) {
                ids.push(el.id);
                res.push(el);
            }
        }
        return res;
    }

    /**
     * This function provides a multi filter for a list of items, providing each item has a unique field with key 'id'.
     * Warning: it does not sort through sub-items of primary items
     * @param list
     * @param stringQuery
     */
    static dynamicMultiFilter(list:Array<any>, stringQuery:string){

        if(stringQuery.length === 0){
            return list;
        }

        //Step 1: separate the different queries
        let queries = stringQuery.trim().split(/\s+/);
        let allLists = [];
        let result = [];

        //Step 2: generate the different result arrays
        for(let query of queries){
            let subList = [];
            for(let item of list){
                let itemValues = Object.values(item);
                for(let itemValue of itemValues){
                    if(typeof itemValue === "string" || typeof itemValue === "number"){
                        let stringItemValue = itemValue+'';
                        if(stringItemValue.toUpperCase().includes(query.toUpperCase())){
                            subList.push(item);
                            break;
                        }
                    }
                }
            }
            allLists.push(subList);
        }

        //Step 3: generate id array steps
        let allListsIds = [];
        let allListsIdsSets = [];

        for(let list of allLists){
            allListsIds.push(list.map((item)=>{
                return item.id
            }));
        }
        for(let idList of allListsIds){
            allListsIdsSets.push(new Set(idList))
        }

        let resultIdArray=[];

        //Step 4: intersect all id sets
        if(allListsIdsSets.length >= 2){
            let referenceIntersectionSet;
            for(let i=1; i < allListsIdsSets.length; i++){
                //first loop
                if(!referenceIntersectionSet){
                    referenceIntersectionSet = new Set([...allListsIdsSets[i-1]].filter(x => allListsIdsSets[1].has(x)));
                }
                else{
                    referenceIntersectionSet = [referenceIntersectionSet].filter(x => allListsIdsSets[i].has(x));
                }
            }
            resultIdArray = Array.from(referenceIntersectionSet)
        }
        else{
            resultIdArray = Array.from(allListsIdsSets[0])
        }

        //Step5: generate array result
        for(let item of list){
            if(resultIdArray.indexOf(item.id) !== -1){
                result.push(item);
            }
        }

        return result;
    }

    static resizeB64Image(base64image: string, width: number = 1080, height: number = 1080){

        return new Promise((resolve)=>{
            let img = new Image();
            img.src = base64image;

            img.onload = () => {

                // Check if the image require resize at all
                if(img.height <= height && img.width <= width) {
                    resolve(base64image)
                }
                else {
                    // Make sure the width and height preserve the original aspect ratio and adjust if needed
                    if(img.height > img.width) {
                        width = Math.floor(height * (img.width / img.height));
                    }
                    else {
                        height = Math.floor(width * (img.height / img.width));
                    }

                    let resizingCanvas: HTMLCanvasElement = document.createElement('canvas');
                    let resizingCanvasContext = resizingCanvas.getContext("2d");

                    // Start with original image size
                    resizingCanvas.width = img.width;
                    resizingCanvas.height = img.height;


                    // Draw the original image on the (temp) resizing canvas
                    resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

                    let curImageDimensions = {
                        width: Math.floor(img.width),
                        height: Math.floor(img.height)
                    };

                    let halfImageDimensions = {
                        width: null,
                        height: null
                    };

                    // Quickly reduce the dize by 50% each time in few iterations until the size is less then
                    // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
                    // created with direct reduction of very big image to small image
                    while (curImageDimensions.width * 0.5 > width) {
                        // Reduce the resizing canvas by half and refresh the image
                        halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
                        halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

                        resizingCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
                            0, 0, halfImageDimensions.width, halfImageDimensions.height);

                        curImageDimensions.width = halfImageDimensions.width;
                        curImageDimensions.height = halfImageDimensions.height;
                    }

                    // Now do final resize for the resizingCanvas to meet the dimension requirments
                    // directly to the output canvas, that will output the final image
                    let outputCanvas: HTMLCanvasElement = document.createElement('canvas');
                    let outputCanvasContext = outputCanvas.getContext("2d");

                    outputCanvas.width = width;
                    outputCanvas.height = height;

                    outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
                        0, 0, width, height);

                    // output the canvas pixels as an image. params: format, quality
                    let base64image = outputCanvas.toDataURL('image/jpeg', 0.85);
                    resolve(base64image)
                }
            };
        })
    }

    static sortFlexnavRoutes(rawRoutes){

        let numberedRoutes = [];
        let textedRoutes = [];
        let allRoutes = [];

        for(let route of rawRoutes){
            if(isNaN(route.number)){
                textedRoutes.push(route)
            }
            else{
                numberedRoutes.push(route);
            }
        }

        numberedRoutes = numberedRoutes.sort((a,b) => {
            let numA = parseFloat(a.number);
            let numB = parseFloat(b.number);
            if (numA < numB)
                return -1;
            if (numA > numB)
                return 1;
            return 0;
        });

        textedRoutes = textedRoutes.sort((a,b)=>{
            if (a.number < b.number)
                return -1;
            if (a.number > b.number)
                return 1;
            return 0;
        });

        allRoutes = allRoutes.concat(numberedRoutes).concat(textedRoutes);

        return allRoutes;
    }

    static sortGeoliteRoutes(rawRoutes){

        let numberedRoutes = [];
        let textedRoutes = [];
        let allRoutes = [];

        for(let route of rawRoutes){
            if(isNaN(route.routeShortName)){
                textedRoutes.push(route)
            }
            else{
                numberedRoutes.push(route);
            }
        }

        numberedRoutes = numberedRoutes.sort((a,b) => {
            let numA = parseFloat(a.routeShortName);
            let numB = parseFloat(b.routeShortName);
            if (numA < numB)
                return -1;
            if (numA > numB)
                return 1;
            return 0;
        });

        textedRoutes = textedRoutes.sort((a,b)=>{
            if (a.routeShortName < b.routeShortName)
                return -1;
            if (a.routeShortName > b.routeShortName)
                return 1;
            return 0;
        });

        allRoutes = allRoutes.concat(numberedRoutes).concat(textedRoutes);

        return allRoutes;
    }


    static remapTripSnapStopTimes(trip){
        if(trip.tripSnap){
            let orderedStopTimeSnaps = trip.tripSnap.stopTimeSnaps.sort((a,b)=>{return a.stopSequence - b.stopSequence});
            let stopTimesOverride = [];
            for(let sts of orderedStopTimeSnaps){
                stopTimesOverride.push({
                    id:sts.stopTime.id,
                    departureTime:sts.departureTime,
                    stopSequence:sts.stopSequence,
                    stop:sts.stopTime.stop,
                    stopTimeSnapId:sts.id,
                    metaData:[]
                })
            }

            for(let sto of stopTimesOverride){
                for(let st of trip.stopTimes){
                    if(sto.id === st.id){
                        if(st.metaData){
                            sto.metaData = st.metaData
                        }
                        break;
                    }
                }
            }

            trip.stopTimes = stopTimesOverride;
        }
    }


    static getServerParameters(){
        return global.serverParameters;
    }

    static isDevServer():boolean{
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "IS_DEV_SERVER"){
                    return true;
                }
            }
        }
        return false;
    }

    // isDevServer(){
    //     if(global.proxyCompany &&
    //         (global.proxyCompany.tag.toUpperCase() === "DEV" ||
    //         global.proxyCompany.tag.toUpperCase() === "LOCALERIC" ||
    //         global.proxyCompany.tag.toUpperCase() === "DEMO" ||
    //         global.proxyCompany.tag.toUpperCase() === "PREX")
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    static isAuthorizedFlexNavServer() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_FLEXNAV"){
                    return true;
                }
            }
        }
    }

    // isAuthorizedFlexNavServer(){
    //     if(global.proxyCompany &&
    //         (
    //             global.proxyCompany.tag.toUpperCase() === "RATP" ||
    //             global.proxyCompany.tag.toUpperCase() === "TVO" ||
    //             global.proxyCompany.tag.toUpperCase() === "TCL" ||
    //             global.proxyCompany.tag.toUpperCase() === "STCLM" ||
    //             global.proxyCompany.tag.toUpperCase() === "SAB" ||
    //             global.proxyCompany.tag.toUpperCase() === "KMEYER" ||
    //             global.proxyCompany.tag.toUpperCase() === "KTOURAINE" ||
    //             global.proxyCompany.tag.toUpperCase() === "ORSAY" ||
    //             global.proxyCompany.tag.toUpperCase() === "CEOBUS" ||
    //             global.proxyCompany.tag.toUpperCase() === "PERRIER" ||
    //             global.proxyCompany.tag.toUpperCase() === "MAT" ||
    //             global.proxyCompany.tag.toUpperCase() === "BMT" ||
    //             global.proxyCompany.tag.toUpperCase() === "TPF" || //Fribourg
    //             global.proxyCompany.tag.toUpperCase() === "PHEBUS" || // Phébus Versailles
    //             global.proxyCompany.tag.toUpperCase() === "KDA" || // Drôme ardêche
    //             global.proxyCompany.tag.toUpperCase() === "KATLANT" || // Keolis atlantique
    //             global.proxyCompany.tag.toUpperCase() === "SDEMO"  // Keolis atlantique
    //         )
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    static isAuthorizedFlexoServer() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_FLEXO"){
                    return true;
                }
            }
        }
    }

    // isAuthorizedFlexoServer(){
    //     if(global.proxyCompany &&
    //         (
    //             global.proxyCompany.tag.toUpperCase() === "KSE" ||
    //             global.proxyCompany.tag.toUpperCase() === "SYNCHRO" ||
    //             global.proxyCompany.tag.toUpperCase() === "CIF"
    //         )
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    static isAuthorizedGeolite() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_GEOLITE"){
                    return true;
                }
            }
        }
    }

    // isAuthorizedSAEServer(){
    //     if(global.proxyCompany &&
    //         (
    //             global.proxyCompany.tag.toUpperCase() === "STCLM" ||
    //             global.proxyCompany.tag.toUpperCase() === "SAB" ||
    //             global.proxyCompany.tag.toUpperCase() === "MLB" ||
    //             global.proxyCompany.tag.toUpperCase() === "SNCFSUB" ||
    //             global.proxyCompany.tag.toUpperCase() === "TGL" ||
    //             global.proxyCompany.tag.toUpperCase() === "T2C" ||
    //             global.proxyCompany.tag.toUpperCase() === "CIF" ||
    //             global.proxyCompany.tag.toUpperCase() === "SDEMO" ||
    //             global.proxyCompany.tag.toUpperCase() === "PREX2"
    //         )
    //     ){
    //         return true;
    //     }
    //     return false;
    // }


    static isAuthorizedIv() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_PASSENGER_INFORMATION"){
                    return true;
                }
            }
        }
    }

    // hasIvServer(){
    //     if(global.proxyCompany &&
    //         (
    //             global.proxyCompany.tag.toUpperCase() === "DEV" ||
    //             global.proxyCompany.tag.toUpperCase() === "MLB" ||
    //             global.proxyCompany.tag.toUpperCase() === "T2C"
    //         )
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    static isAuthorizedTicketingServer() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_TICKETING"){
                    return true;
                }
            }
        }
    }

    // isAuthorizedTicketingServer(){
    //     if(global.proxyCompany &&
    //         (
    //             global.proxyCompany.tag.toUpperCase() === "MLB"
    //         )
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    static isAuthorizedTADServer() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_TAD"){
                    return true;
                }
            }
        }
    }

    // isAuthorizedTADServer(){
    //     if(global.proxyCompany &&
    //         (
    //             global.proxyCompany.tag.toUpperCase() === "CIF" ||
    //             global.proxyCompany.tag.toUpperCase() === "TGL" ||
    //             global.proxyCompany.tag.toUpperCase() === "SDEMO"
    //         )
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    static isAuthorizedAccessCom() {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "ACCESS_COM"){
                    return true;
                }
            }
        }
    }

    static proxyCompanyTag(proxy) {
        let serverParameters = global.serverParameters;
        if(serverParameters){
            for(let parameter of serverParameters){
                if(parameter.itemKey === "PROXY_COMPANY_TAG" && parameter.itemStringValue === proxy){
                    return true
                }
            }
        }
    }

    static filterRelevantGeoliteRoutes(rawRoutes){

        let result = [];

        if(global.proxyCompany.tag === "T2C" || global.proxyCompany.tag === "DEV" || global.proxyCompany.tag === "PREX2" || global.proxyCompany.tag === "PREXLOR3"){
            let relevantRouteShortNames = ["26","28","31","32","33","34","35","36","37","41","scolaire","83","91"];
            for(let route of rawRoutes){
                if(relevantRouteShortNames.indexOf(route.routeShortName) !== -1){
                    let tmpRoute = route;
                    tmpRoute.routeDesc = tmpRoute.routeDesc.substring(6);
                    result.push(tmpRoute);
                }
            }
        }

        else{
            result = rawRoutes;
        }

        return result;
    }

}



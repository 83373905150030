import {RESTManager} from "../../sharedCored/api/RESTManager";
import { Route} from "../entities/Route";
import {Variant} from "../entities/Variant";
import {Ride} from "../entities/Ride";
import {POI} from "../entities/POI";
import {Track} from "../entities/Track";
import {Subtrack} from "../entities/Subtrack";
import {DangerZone} from "../entities/DangerZone";
import {Direction} from "../entities/Direction";
import {LatLng} from "../../../geo-utilities/entities/LatLng";
import {Encoder} from "../../../geo-utilities/others/Encoder";
import {ReRoutingPath} from "../entities/ReRoutingPath";
import {TunnelZone} from "../entities/TunnelZone";
import {ConvertiblePosition} from "../../../geo-utilities/entities/ConvertiblePosition";


export class GeoDriveAPI {


    static getRouteWithAllVariantsAndRides(routeId)  {
        return new Promise((resolve, reject)=> {
            RESTManager.get(`/shared/route/${routeId}/variant/rides/all`)
                .then((response:any) => {
                    let route = Route.mapRoute(response.data.route);
                    let variants:Array<Variant> = [];
                    for(let container of response.data.variants) {
                        let v = Variant.mapVariant(container.variant);
                        if(container.rides.length) {
                            let r = Ride.mapRide(container.rides[0].ride);
                            if("tracks" in container.rides[0].ride) {
                                let tracks = [];
                                for(let track of container.rides[0].ride.tracks) {
                                    let mappedTrack:Track = Track.mapTrack(track)
                                    if("subtracks" in track) {
                                        let subtracks = [];
                                        for(let subtrack of track.subtracks) {
                                            subtracks.push(Subtrack.mapSubtrack(subtrack));
                                        }
                                        mappedTrack.subtracks = subtracks;
                                    }
                                    tracks.push(mappedTrack);
                                }
                                r.tracks = tracks;
                            }
                            v.rides =  [r];
                        }
                        variants.push(v);
                    }
                    route.variants = variants;
                    resolve(route);
                });
        });
    }


    static getRoutes() : Promise<Array<Route>>  {
        return new Promise((resolve, reject)=> {
            RESTManager.get("/shared/routes")
                .then((response:any) => {
                    resolve(Route.mapRoutes(response.data));
                });
        });
    }

    // static getTrackEditData(trackId) : Promise<Array<Route>>  {
    //     return new Promise((resolve, reject)=> {
    //         RESTManager.get(`/shared/track/${trackId}/data`)
    //             .then((response:any) => {
    //                 resolve(Route.mapRoutes(response.data));
    //             });
    //     });
    // }

    static getTrackEditData(trackId) : Promise<any>  {
        return new Promise((resolve, reject)=> {
            RESTManager.get(`/shared/track/${trackId}/data`)
                .then((response:any) => {
                    let d = response.data;
                    let subtracks:Array<Subtrack> = [];
                    for(let s of d.subtracks) {
                        let latLngs_raw = [];
                        for(let f of d.files) {
                            if(f.subtrack === s.id) {
                                latLngs_raw = Encoder.decodePolyline(f.polyLine);
                                break;
                            }
                        }
                        let subtrack:Subtrack = Subtrack.mapSubtrack(s);
                        let pts:Array<LatLng> = [];
                        for(let latLng of latLngs_raw) {
                            pts.push(new ConvertiblePosition(latLng.lat, latLng.lng));
                        }
                        subtrack.points = pts;
                        subtracks.push(subtrack);
                    }

                    resolve({
                        dangerZones: DangerZone.mapDangerZones(d.dangerZones),
                        directions: Direction.mapDirections(d.directions),
                        pois: POI.mapPOIs(d.pois),
                        subTracks: subtracks,
                        reRoutingPaths: ReRoutingPath.mapArray(d.reRoutingPaths),
                        ride: Ride.mapRide(d.ride),
                        route: Route.mapRoute(d.route),
                        track : Track.mapTrack(d.track),
                        variant : Variant.mapVariant(d.variant),
                        tunnelZones : TunnelZone.mapArray(d.tunnelZones)
                    });
                });
        });
    }

    static getTrackEditDataRaw(trackId) : Promise<any>  {
        return new Promise((resolve, reject)=> {
            RESTManager.get(`/shared/track/${trackId}/data`)
                .then((response:any) => {
                    resolve(response.data)
                })
                .catch((err)=>{
                    reject(err);
                })
        });
    }



static getVariantCount(routeId) {
    return new Promise((resolve, reject) => {
        RESTManager.get(`/shared/route/${routeId}/variant/counts`)
            .then((response:any) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
}

// static getVariantQueryResults(needle:string) {
    //     return new Promise((resolve, reject) => {
    //         RESTManager.get(FOSRouter.getRoute("api_get_variant_query_results", {needle:needle}))
    //             .then((response:any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             })
    //     });
    // }

    static getVariantQueryResults(needle:string) {
        return new Promise((resolve, reject) => {
            // route is : /shared/variants/{needle}/qr
            RESTManager.get("/shared/variants/"+encodeURI(needle)+"/qr")
                .then((response:any) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    }
}

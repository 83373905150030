import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {GeoDriveAPI} from "../API/GeoDriveApi";
import {Route} from "../entities/Route";
import './RoutesListView.css'
import Icon from "@material-ui/core/Icon";
import HAutocompleteAsync from "../../sharedCored/components/HautocompleteAsync";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import logoHubup from "../../../assets/hubup_logo_skewed_blue_white_bg.svg"
import {IconButton, Menu, MenuItem} from "@material-ui/core";

class RoutesListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routes: null,
            sortedRoutes: null,
            sortedAndFilteredRoutes: null,
            anchorEl: false
        }
    }

    componentDidMount() {
        this.getRoutes();
    }

    getRoutes() {
        GeoDriveAPI.getRoutes()
            .then((res: Array<Route>) => {
                let numbered = [];
                let notNumbered = [];
                if (res && res.length) {
                    for (let route of res) {
                        if (route.number === (+route.number).toString()) {
                            route.number = +route.number;
                            numbered.push(route);
                        } else {
                            notNumbered.push(route);
                        }
                    }
                    numbered.sort((a, b) => {
                        return a.number > b.number ? 1 : a.number === b.number ? 0 : -1
                    })
                    notNumbered.sort((a, b) => {
                        var nameA = a.number.toLowerCase(), nameB = b.number.toLowerCase();
                        if (nameA < nameB) //sort string ascending
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0;
                    });
                }

                this.setState({
                        routes: res,
                        sortedRoutes: [...numbered, ...notNumbered],
                        sortedAndFilteredRoutes: [...numbered, ...notNumbered]

                    },
                );
            });
    }

    handleClickAnchorEl(event) {
        this.setState({anchorEl: event.currentTarget});
    };

    handleCloseAnchorEl() {
        this.setState({anchorEl: false});
    };

    logout() {
        this.setState({anchorEl: false}, global.emitter.emit('logout')
        );
    };

    getData = async (query) => {
        try {
            const data = await GeoDriveAPI.getVariantQueryResults(query);
            return data
        } catch (error) {
            console.warn(error)
        }
    }

    render() {
        // let isCompanyLoggedWithUrlParameters = global.proxyCompany.loggedWithUrlParams
        return (
            <div>
                <div>
                    <div className={"top-app-bar"}>

                        <Link to={"/"} style={{textDecoration: 'none', display: "flex", alignItems: "center"}}>
                            <img className='top-app-bar-logo' src={logoHubup} alt="Logo Hubup"/>
                        </Link>
                        <div className={'top-app-bar-searchbar'}>
                            <HAutocompleteAsync

                                placeholder={"Rechercher"}
                                variant="outlined"
                                showClearButton={true}
                                getItems={(query) => this.getData(query)}
                                minSearchLength={4}
                                maxHeight={300}
                                disabled={false}
                                align={'left'}
                                onHandleClick={(route) => {
                                    this.accessRouteFromSearchbox(route)
                                }}
                                renderItem={(item) => {
                                    return (
                                        this.renderResultsFormSearchBox(item)
                                    )
                                }}
                            />
                        </div>


                        <div style={{flex: 1}}/>
                        {
                            // !isCompanyLoggedWithUrlParameters &&
                            <>
                                <Button variant={"contained"} size={"small"} className={'top-app-bar-desktop-button'}
                                        onClick={(event) => this.handleClickAnchorEl(event)}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <Icon style={{
                                            color: '#555555',
                                            marginRight: 8,
                                            fontSize: 16
                                        }}>account_circle</Icon>
                                        {global.user && <span
                                            style={{color: '#555555'}}>{global.user.firstName} {global.user.lastName}</span>}
                                    </div>
                                </Button>
                                <IconButton onClick={(event) => this.handleClickAnchorEl(event)}
                                            className={'top-app-bar-mobile-button'}>
                                    <Icon style={{color: '#555555', marginRight: 8, fontSize: 24}}>account_circle</Icon>
                                </IconButton>
                            </>
                        }


                        <Menu
                            id="creation-menu"
                            anchorEl={this.state.anchorEl}
                            open={this.state.anchorEl}
                            onClose={() => {
                                this.handleCloseAnchorEl()
                            }}
                        >
                            <MenuItem onClick={() => {
                                this.logout()
                            }}>
                                Déconnexion
                            </MenuItem>
                        </Menu>

                    </div>
                </div>

                {!this.state.routes ?
                    <LinearProgress color="primary"/>
                    :
                    <div className={"routes-list-wrapper"}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            minHeight: 60,
                            paddingTop: 50,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingBottom: 50,
                            backgroundColor: '#FAFAFA',
                            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 110px)'
                        }}
                             className={"styled-scroll"}>

                            {this.state.sortedAndFilteredRoutes.map((route: Route) => (
                                <div style={{minHeight: 50, backgroundColor: '#FFFFFF', padding: 10, width: 400}}
                                     key={route.id}
                                     className={"hoverable-line"}>
                                    <Link to={"/flexnav/routes/" + route.id}
                                          style={{
                                              width: "100%",
                                              height: "100%",
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                              textDecoration: "none"
                                          }}>

                                        <div style={{
                                            height: 36,
                                            minWidth: 36,
                                            borderRadius: 4,
                                            backgroundColor: route.color,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <span
                                                style={{color: "#FFFFFF", fontSize: 20, marginLeft: 7, marginRight: 7}}
                                                className={"text-bordered"}>{this.renderMaxLength(route.number)}</span>
                                        </div>

                                        <div style={{
                                            flex: 1, flexDirection: 'column', display: 'flex',
                                            justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 10
                                        }}>

                                            <span style={{color: '#222222', marginBottom: 2}}>{route.name}</span>
                                            <span
                                                style={{color: '#555555', textAlign: 'left'}}>{route.description}</span>

                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        );
    }

    accessRouteFromSearchbox(route) {
        this.props.history.push("/flexnav/track/" + route.track_id);
    }

    renderResultsFormSearchBox(item) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '10px',
                cursor: 'pointer',
                height: 60
            }}
                 className='linkTools'>
                <div style={{
                    height: 36,
                    minWidth: 36,
                    borderRadius: '4px',
                    backgroundColor: `#${item.route_color}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <span style={{color: "#FFFFFF", fontSize: 24, marginLeft: 7, marginRight: 7}}
                          className={"text-bordered"}>{this.renderMaxLength(item.route_number)}</span>
                </div>
                <div style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingLeft: 10
                }}>

                    <p style={{margin: 0, width: "100%", fontSize: 14, textAlign: "left", color: '#222222'}}>
                        {item.departure + " "}
                        <strong>⟶</strong>
                        {" " + item.arrival}
                    </p>
                    <p style={{margin: 0, marginTop: 5, textDecoration: 'italic', color: '#3F98F4', fontSize: 14}}>
                        {item.description}
                    </p>
                </div>
            </div>
        )
    }

    renderMaxLength(number) {
        if (number.length < 6) {
            return number
        } else {
            if (typeof number === "number") {
                const convert = String(number)
                return convert.substring(0, 6)
            } else if (typeof number === "string") {
                return number.substring(0, 6)
            }
        }
    }
}

export default RoutesListView;
import {Track} from "./Track";
import {POI} from "./POI";
import {Geodesic} from "../../../geo-utilities/others/Geodesic";
import {LatLng} from "../../../geo-utilities/entities/LatLng";
import {ConvertiblePosition} from "../../../geo-utilities/entities/ConvertiblePosition";
declare var google:any;


export class Subtrack {
    track:Track;
    id:number;
    type:string;
    used:boolean;
    production:boolean;
    points:Array<ConvertiblePosition>;
    minLat:number;
    minLng:number;
    maxLat:number;
    maxLng:number;
    color:string;
    pois:Array<POI>;

    constructor(id: number, type: string, used: boolean, bounds: any, color:any) {
        this.id = id;
        this.type = type;
        this.used = used;
        this.points = [];
        this.color = color;
        try {
            this.minLat = bounds[0].lat;
            this.minLng = bounds[0].lng;
            this.maxLat = bounds[1].lat;
            this.maxLng = bounds[1].lng;
        } catch(error) {
            this.minLat = 0;
            this.minLat = 0;
            this.maxLat = 0;
            this.maxLng = 0;
            // console.log("Error while getting the bounds");
        }
    }

    pushPoint(point:Point) {
      if(!this.points) {
        this.points = [];
      }
      this.points.push(point);

    }



    static create() {
    return new Subtrack(null, "undefined", false, null, '#3F98F4');
    }



    dumpInformations() {
        return JSON.stringify({
            minLat: this.minLat,
            maxLat: this.maxLat,
            minLng: this.minLng,
            maxLng: this.maxLng,
            color: this.color.toString(),
            type: this.type
        })
    }

    /**
     * Longueur de la subtrack
     * @returns {number}
     */
    getLength() :number {
        if(this.points) {
            let result = 0;
            for(let i = 0; i < this.points.length - 1; i++) {
                const cp = this.points[i];
                const cpn = this.points[i + 1];
                result += Geodesic.computeDistanceBetween(cp.toLatLngLiteral(), cpn.toLatLngLiteral())
            }
            return result;
        } else {
            return 0;
        }
    }






    prepare() {
        let result = [];
        for(let p of this.points) {
          result.push({ lat : p.lat, lng : p.lng });
        }
        return { points : result };
    }


    getCenterLat():number {
        if(this.minLat && this.maxLat) {
            return (this.maxLat + this.minLat) / 2
        }
        return 0;
    }

    getCenterLng():number {
        if(this.minLng && this.maxLng) {
            return (this.maxLng + this.minLng) / 2
        }
        return 0;
    }

    getGoogleLatLngBounds(delta:number = 0) : Promise<any> {
      return new Promise((resolve, reject) => {
        this.waitGoogleLoading()
          .then(() => {
              this.minLat = Math.min.apply(null, this.points.map(function(d) { return d.latitude }));
              this.maxLat = Math.max.apply(null, this.points.map(function(d) { return d.latitude }));
              this.minLng = Math.min.apply(null, this.points.map(function(d) { return d.longitude }));
              this.maxLng = Math.max.apply(null, this.points.map(function(d) { return d.longitude }));
              // console.log("computed bounds : " + this.minLat + ", " + this.maxLat + " //" + this.minLng + ", " + this.maxLng);
              resolve(new google.maps.LatLngBounds(
                {lat: this.minLat - delta, lng: this.minLng - delta},
                {lat: this.maxLat + delta, lng: this.maxLng + delta}
              ));
            });
      });


    }

    /**
     * Mapping the rides
     * @param rawArray
     * @returns {Array}
     */
    static mapSubtracks(rawArray:any) : Array<Subtrack> {
      console.log(rawArray);
        let result = [];
        try {
            for(let o of rawArray) {
                result.push(Subtrack.mapSubtrack(o));
            }
        } catch(err) {
            console.log(err);
            return result;
        }
        return result;
    }

    static mapSubtrack(rawObject:any) : Subtrack {
        let res = new Subtrack(rawObject.id, rawObject.type, rawObject.used,rawObject.bounds, rawObject.color);
        if("production" in rawObject) {
            res.production = rawObject.production;
        }
        return res;
    }

    static mapPointsFileContent(rawArray) : Array<LatLng> {
        let points:Array<LatLng> = [];
        for(let o of rawArray) {
            points.push(new LatLng(o.lat, o.lng));
        }
        return points;
    }

    static mapPointsFileContentWithDelimiters(rawArray) : Array<any> {
        let points:Array<any> = [];
        for(let o of rawArray) {
            points.push({
                lat:o.lat,
                lng:o.lng,
                delimiter:o.delimiter ? o.delimiter : null
            });
        }
        return points;
    }


    static clone(subtrack:Subtrack):Subtrack{
        return Subtrack.mapSubtrack(Object.assign({},subtrack))
    }
}
